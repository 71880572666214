@import "../scss/variables";

body.transparent-mode {
    color: $white;
    background-color: $transparent-body;
}

body.transparent-mode.bg-img1 {
    background: url(../images/media/bg-img1.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

body.transparent-mode.bg-img2 {
    background: url(../images/media/bg-img2.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

body.transparent-mode.bg-img3 {
    background: url(../images/media/bg-img3.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

body.transparent-mode.bg-img4 {
    background: url(../images/media/bg-img4.jpg);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

.transparent-mode {

    &.bg-img1,
    &.bg-img2,
    &.bg-img3,
    &.bg-img4 {
        .login-img::before {
            background: none;
        }
    }
}

body.transparent-mode *::-webkit-scrollbar-thumb,
body.transparent-mode *:hover::-webkit-scrollbar-thumb {
    background: var(--transparent-body);
}

.transparent-mode {
    table.dataTable>tbody>tr.child ul.dtr-details>li {
        border-bottom: 1px solid $transparent-border;
    }

    .dtr-bs-modal .dtr-details {
        border: 1px solid $transparent-border;
    }

    .dtr-bs-modal .dtr-details tr td:first-child {
        border-right: 1px solid $transparent-border;
    }

    caption {
        color: #505662;
    }

    hr {
        border-top-color: rgba(255, 255, 255, 0.2);
        background-color: transparent;
    }

    mark,
    .mark {
        background-color: #fcf8e3;
    }

    .list-unstyled li {
        border-bottom-color: $transparent-border;
    }

    kbd {
        color: $white;
        background-color: $white-1;
    }

    pre {
        color: $white;
    }

    @media print {

        pre,
        blockquote {
            border-color: #adb5bd;
        }

        .badge {
            border-color: $black;
        }

        .table td,
        .text-wrap table td,
        .table th,
        .text-wrap table th {
            background-color: $transparent-theme  !important;
        }

        .table-bordered th,
        .text-wrap table th,
        .table-bordered td,
        .text-wrap table td {
            border-color: $transparent-border  !important;
        }
    }

    body * {

        &::-webkit-scrollbar-thumb,
        &:hover::-webkit-scrollbar-thumb {
            background: $transparent-theme;
        }
    }

    code {
        background: transparent;
        border-color: transparent;
        color: $white-7;
    }

    pre {
        color: $white-5;
        background-color: $transparent-theme;
        text-shadow: 0 1px $transparent-theme;
    }

    .section-nav {
        background-color: #f8f9fa;
        border-color: $transparent-border;
    }

    /*------ Accordion -------*/
    .accordionjs .acc_section {
        border-color: $transparent-border;

        .acc_head h3:before {
            color: $white;
        }

        .acc-header {
            background: $transparent-theme;
        }

        .acc_head {
            background: $transparent-theme;
        }

        &.acc_active>.acc_head {
            color: $white  !important;
            border-bottom-color: $transparent-border;
        }
    }

    /* #accordion rotate icon option */
    .accordion-item {
        border-color: $transparent-border;
        background: transparent;
    }

    .accordion-button {
        color: $white;

        &:focus {
            border-color: $transparent-border;
        }

        &:not(.collapsed) {
            color: $white;
            background: $transparent-body;
        }

        &::after {
            color: $white-7;
        }
    }

    #accordion .panel-default {
        >.panel-heading {
            border: 0px solid $transparent-border;
        }

        .collapsing .panel-body {
            border-top: 0px solid transparent;
        }
    }

    /*----- Avatars -----*/
    .avatar-status {
        border: 2px solid $transparent-body;
    }

    .avatar-list-stacked .avatar {
        box-shadow: 0 0 0 2px $transparent-theme;
    }

    /*-----Badges-----*/
    .btn-custom {
        background: $transparent-theme;
        color: $white-7;
    }

    .blockquote-footer {
        color: $white-7;
    }

    blockquote {
        color: $white-7;
        border-color: $transparent-border;
    }

    .blockquote-reverse {
        border-color: $transparent-border;
    }

    /*------ Breadcrumb ------*/
    .breadcrumb-item {
        a {
            color: $white-7;
        }

        +.breadcrumb-item::before {
            color: $white-5;
        }
    }

    .breadcrumb1 {
        background-color: transparent;
    }

    .breadcrumb-item1 {
        a:hover {
            color: $primary-1;
        }

        +.breadcrumb-item1::before {
            color: $white-6;
        }

        &.active {
            color: $white-7;
        }
    }

    .note-btn.active {
        background-color: $transparent-theme  !important;
        border-color: $transparent-border  !important;
        color: $white  !important;
    }

    .btn-default {
        color: $default-color;
        background: #e9e9f1;
        border-color: #e9e9f1;

        &:hover {
            color: $default-color;
            background-color: #e3e3ef;
            border-color: #e3e3ef;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2px #e9e9f1;
        }

        &.disabled,
        &:disabled {
            color: $default-color;
            background-color: #e9e9f1;
            border-color: #e9e9f1;
        }

        &:not(:disabled):not(.disabled) {

            &:active,
            &.active {
                color: $default-color;
                background-color: #e9e9f1;
                border-color: #e9e9f1;
            }
        }
    }

    .btn-light {
        color: $white;
        background-color: $transparent-theme;
        border-color: $transparent-border;

        &:hover {
            color: $white;
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
        }

        &.disabled,
        &:disabled {
            color: $white;
            background-color: $transparent-theme;
            border-color: $transparent-border;
            opacity: 0.7;
        }

        &:not(:disabled):not(.disabled) {

            &:active,
            &.active {
                color: #495057;
                background-color: $transparent-theme;
                border-color: $transparent-border;
            }
        }
    }

    .btn-outline-default {
        color: $white;
        background: transparent;
        border-color: $transparent-border;

        &:hover {
            color: $default-color;
            background: #e9e9f1;
        }
    }

    .btn-white {
        color: $white;
        background-color: $transparent-theme;
        border-color: $transparent-border;

        &:hover {
            color: $white;
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }

        &:focus,
        &.focus {
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
        }

        &.disabled,
        &:disabled {
            color: #495057;
            background-color: $transparent-theme;
            border-color: $white;
        }

        &:not(:disabled):not(.disabled) {

            &:active,
            &.active {
                color: #495057;
                background-color: #545478;
                border-color: $transparent-border;
            }
        }
    }

    .show>.btn-white.dropdown-toggle {
        color: #495057;
        background-color: #545478;
        border-color: $transparent-border;
    }

    .btn-check {

        &:active+.btn-outline-primary,
        &:checked+.btn-outline-primary {
            background-color: $primary-1;
            border-color: $primary-1;
        }
    }

    .btn-outline-primary {
        border-color: $white-4;
        color: $white-5;

        &.active,
        &.dropdown-toggle.show,
        &:active {
            background-color: $primary-1;
            border-color: $primary-1;
        }
    }

    /*------ Card -------*/
    .card {
        background-color: $transparent-theme;
        border: inherit !important;
        // box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3);
    }

    .card-footer {
        // background-color: rgba(0, 0, 0, 0.03);
        border-top-color: $transparent-border;
        color: $white;
    }

    /*------ Default Card Values -------*/
    .card .card {
        border-color: $transparent-border;
        background: transparent;
        box-shadow: 0px 0px 10px $transparent-theme;
    }

    .card-body+.card-body {
        border-top-color: $transparent-border;
    }

    .card-header {
        border-bottom-color: $transparent-border;
    }

    .card-title {
        small {
            color: #9aa0ac;
        }
    }

    .card-subtitle {
        color: #9aa0ac;
    }

    .card-body+.card-table {
        border-top-color: $transparent-border;
    }

    .card-body+.card-list-group {
        border-top-color: $transparent-border;
    }

    .card-options {
        color: #9aa0ac;

        a:not(.btn) {
            color: $white-7;

            &:hover {
                color: $white;
            }
        }
    }

    /*Card maps*/
    .card-map {
        background: #e9ecef;
    }

    .card .box {
        h2 {
            color: #262626;

            span {
                color: $white;
            }
        }

        p {
            color: #262626;
        }
    }

    /*------ Card -------*/
    .card-footer {
        // background-color: rgba(0, 0, 0, 0.03);
        border-top-color: $transparent-border;
        color: rgba(255, 255, 255, 0.5);
    }

    /*------ Default Card Values -------*/
    .card-body+.card-body {
        border-top-color: $transparent-border;
    }

    .card-title {
        small {
            color: #9aa0ac;
        }
    }

    .card-subtitle {
        color: #9aa0ac;
    }

    .card-body+.card-table {
        border-top-color: $transparent-border;
    }

    .card-body+.card-list-group {
        border-top-color: $transparent-border;
    }

    .card-options {
        color: #9aa0ac;

        a:not(.btn) {
            color: $white-7;

            &:hover {
                color: $white;
            }
        }
    }

    /*Card maps*/
    .card-map {
        background: #e9ecef;
    }

    .card .box {
        h2 {
            color: #262626;

            span {
                color: $white;
            }
        }

        p {
            color: #262626;
        }
    }

    .cardheader-tabs .card-header {
        border-bottom-color: $transparent-border;
    }

    /*------ Carousel -------*/
    .carousel-control-prev,
    .carousel-control-next {
        color: $white;
    }

    .carousel-control-prev {

        &:hover,
        &:focus {
            color: $white;
        }
    }

    .carousel-control-next {

        &:hover,
        &:focus {
            color: $white;
        }
    }

    .carousel-indicators,
    .carousel-indicators1,
    .carousel-indicators2,
    .carousel-indicators3,
    .carousel-indicators4,
    .carousel-indicators5 {
        li {
            background-color: rgba(255, 255, 255, 0.5);
        }

        .active {
            background-color: $white;
        }
    }

    .carousel-caption {
        color: $white;
    }

    .carousel-item-background {
        background: rgba(0, 0, 0, 0.5);
    }

    /*------ Carousel -------*/
    .carousel-control-prev,
    .carousel-control-next {
        color: $white;
    }

    .carousel-control-prev {

        &:hover,
        &:focus {
            color: $white;
        }
    }

    .carousel-control-next {

        &:hover,
        &:focus {
            color: $white;
        }
    }

    .carousel-indicators,
    .carousel-indicators1,
    .carousel-indicators2,
    .carousel-indicators3,
    .carousel-indicators4,
    .carousel-indicators5 {
        li {
            background-color: rgba(255, 255, 255, 0.5);
        }

        .active {
            background-color: $white;
        }
    }

    .carousel-caption {
        color: $white;
    }

    .carousel-item-background {
        background: rgba(0, 0, 0, 0.5);
    }

    /*------Drop Downs-------*/
    .dropdown-menu {
        color: $white-7;
        background-color: $transparent-body;
        border-color: $transparent-border;
    }

    .dropdown-divider {
        border-top-color: $transparent-border;
    }

    .drop-heading {
        color: $white;
    }

    .dropdown-item {
        color: $white-7;
    }

    .dropdown-item {

        &:hover,
        &:focus,
        &.active,
        &:active {
            background-color: $transparent-theme;
        }

        &.disabled,
        &:disabled {
            color: $white;
        }
    }

    .dropdown-menu.show {
        border-color: $transparent-border;
        box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
    }

    .dropdown-header {
        color: $white-7;
    }

    .dropdown-item-text {
        color: $white;
    }

    /*-----List Of Dropdwons-----*/
    btn.dropdown-toggle~.dropdown-menu,
    ul.dropdown-menu li.dropdown ul.dropdown-menu {
        background-color: #f4f4f4 !important;
        background-color: white !important;
        border: 0 solid #4285f4 !important;
        box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
    }

    .dropdown-menu {
        background-color: $primary-1;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

        >li>a {
            color: $white;

            &:hover,
            &:focus {
                color: $white-7;
                background-color: $transparent-theme;
            }
        }

        .divider {
            background-color: $transparent-border;
        }

        .dropdown-plus-title {
            color: $white  !important;
            border: 0 solid $transparent-border  !important;
            border-bottom: 1px solid $transparent-border  !important;
        }
    }

    .dropdown-menu-header {
        border-bottom-color: $transparent-border;

        label {
            color: $white-7;
        }
    }

    .dropdown-menu-arrow:before,
    .dropdown-menu.header-search:before {
        background: $primary-1;
        border-top-color: $transparent-border;
        border-left-color: $transparent-border;
    }

    .dropdown-menu {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }

    .dropdown-toggle .dropdown-label {
        background-color: $transparent-theme;
    }

    /*------- Forms -------*/
    .form-control {
        color: $white;
        background-color: transparent;
        border-color: $transparent-border  !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &::-ms-expand {
            background-color: transparent;
        }

        &:focus {
            color: $white-7;
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }

        &::placeholder {
            color: $white-7  !important;
        }

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            color: $white  !important;
        }

        &:disabled,
        &[readonly] {
            background-color: $transparent-theme
        }

        &::-webkit-file-upload-button {
            color: $white;
            background-color: $transparent-body;
        }

        &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
            background-color: $transparent-theme;
        }
    }

    .form-control-plaintext {
        color: $white-7;
    }

    .form-check-input:disabled~.form-check-label {
        color: #505662;
    }

    .was-validated .custom-control-input:valid:focus~.custom-control-label::before,
    .custom-control-input.is-valid:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px $transparent-theme, 0 0 0 2px rgba(9, 173, 149, 0.25);
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid,
    .was-validated .form-select:invalid,
    .form-select.is-invalid {
        border-color: #fb7d92;
        background-repeat: no-repeat;
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus,
    .was-validated .form-select:invalid:focus,
    .form-select.is-invalid:focus {
        border-color: #fb7d92;
    }

    .was-validated .form-check-input:invalid~.form-check-label,
    .form-check-input.is-invalid~.form-check-label,
    .was-validated .custom-control-input:invalid~.custom-control-label,
    .custom-control-input.is-invalid~.custom-control-label {
        color: #fb7d92;
    }

    .was-validated .custom-control-input:invalid~.custom-control-label::before,
    .custom-control-input.is-invalid~.custom-control-label::before {
        background-color: #ec8080;
    }

    .was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
    .custom-control-input.is-invalid:checked~.custom-control-label::before {
        background-color: #fb7d92;
    }

    .was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
    .custom-control-input.is-invalid:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px $transparent-theme, 0 0 0 2px rgba(232, 38, 70, 0.25);
    }

    .form-control.header-search {
        background: $transparent-theme;
        border-color: rgba(225, 225, 225, 0.1);
        color: $white;

        &::placeholder {
            color: $white;
        }

        &:hover,
        &:focus {
            border-color: rgba(225, 225, 225, 0.1);
        }
    }

    .form-required {
        color: #fb7d92;
    }

    .form-check-input:focus {
        border-color: $primary-1;
    }

    .form-check-input {
        background: $white-1;
        border-color: $transparent-border;
    }

    .input-group-text {
        color: $white;
        background-color: $primary-1;
        border-color: $transparent-border;
    }

    .input-indec .input-group-btn>.btn {
        border-color: $transparent-border;
    }

    .input-group-text {
        color: $white;
        border-color: $transparent-border  !important;
    }

    .input-group-text.bg-white {
        background-color: transparent !important;
    }

    .input-indec .input-group-btn>.btn {
        border-color: $transparent-border;
    }

    /*------ Modal -------*/
    .modal-content {
        background-color: $primary-1;
        border-color: $transparent-border;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
    }

    .modal-backdrop {
        background-color: $black;
    }

    .modal-header {
        border-bottom-color: $transparent-border;
    }

    .modal-footer {
        border-top-color: $transparent-border;
    }

    .modal.effect-just-me {
        .modal-content {
            background-color: #16192f;
            border-color: #4d4e50;
        }

        .btn-close {
            color: $white;
        }

        .modal-header {
            background-color: transparent;
            border-bottom-color: rgba(255, 255, 255, 0.1);

            h6 {
                color: $white;
            }
        }

        .modal-body {
            color: rgba(255, 255, 255, 0.8);

            h6 {
                color: $white;
            }
        }

        .modal-footer {
            background-color: transparent;
            border-top-color: $transparent-border;
        }
    }

    /*------ Navigation -------*/
    .nav-tabs {
        border-bottom-color: $transparent-border;
    }

    .nav-pills {

        .nav-link.active,
        .show>.nav-link {
            color: $white;
        }
    }

    .nav.nav-pills.nav-stacked.labels-info p {
        color: #9d9f9e;
    }

    .nav.nav-pills .nav-item .nav-link-icon {
        color: $white;
    }

    .nav1 {
        background: $transparent-body;
    }

    .nav-item1 {

        &:hover:not(.disabled),
        &.active {
            color: $white;
        }
    }

    .nav-item1 .nav-link {
        color: $white-8;

        &.active {
            color: $white;
        }

        &.disabled {
            color: $white-3;
        }
    }

    .nav-tabs {
        border-bottom-color: $transparent-border;

        .nav-item1 {
            &.nav-link {
                border-color: transparent;
            }

            .nav-link {
                color: inherit;
                color: $white-7;
                transition: 0.3s border-color;

                &:hover:not(.disabled),
                &.active {
                    color: $white;
                }
            }
        }

        .nav-submenu .nav-item1 {
            color: #9aa0ac;

            &.active {
                color: #467fcf;
            }

            &:hover {
                color: $white;
                background: rgba(0, 0, 0, 0.024);
            }
        }
    }

    .nav-link {
        color: $primary-1;

        &.icon i {

            // color: #f7f7f7 !important;
            &::after {
                background: rgba($primary-1, 0);
            }
        }
    }

    .app-header .header-right-icons .nav-link.icon {
        color: $white;
    }

    .app-header .header-right-icons .nav-link.icon:hover {
        color: $white  !important;
    }

    .nav-tabs {
        color: $black;

        .nav-link {
            color: inherit;
            color: $white-7;

            &:hover:not(.disabled),
            &.active {
                color: $white;
            }

            &.disabled {
                color: #868e96;
                background-color: transparent;
                border-color: transparent;
            }
        }

        .nav-submenu {
            background: $transparent-theme;
            border-color: $transparent-border;
            border-top: none;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

            .nav-item {
                color: #9aa0ac;

                &:hover {
                    color: $white;
                    text-decoration: none;
                    background: rgba(0, 0, 0, 0.024);
                }
            }
        }
    }

    .page-link {
        background-color: $transparent-theme;
        border-color: $transparent-border;
        color: $white-7;

        &:hover {
            background-color: $transparent-theme;
        }
    }

    .page-item {
        &.active .page-link {
            color: $white;
        }

        &.disabled .page-link {
            color: rgba(255, 255, 255, 0.3);
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }
    }

    .page-header {
        border-color: transparent;
    }

    .panel-title-landing {
        color: #1643a3 !important;
    }

    .panel-footer-landing {
        border: solid 2px #1643a3 !important;
        border-top: none !important;
        background: #f7f7f7;
    }

    .panel-footer {
        background-color: $transparent-theme;
        border-top-color: $transparent-border;
        border-left-color: $transparent-border;
        border-right-color: $transparent-border;
    }

    .panel-group .panel,
    .panel-group1 .panel {
        border-color: $transparent-border;
    }

    .panel-default>.panel-heading {
        background-color: $transparent-body;
        border-color: $transparent-border;

        +.panel-collapse>.panel-body {
            border: 0px solid $transparent-border;
        }
    }

    .panel1 {
        border-color: $white;

        &:last-child {
            border-bottom: none;
        }
    }

    .panel-body1 {
        background: $transparent-theme;
    }

    .panel-group1 .panel-body {
        border: 0px solid $transparent-border;
    }

    .panel-title1 a {
        color: $white  !important;
    }

    .panel-title a:hover {
        color: $text-color  !important;
    }

    /* #bs-collapse icon scale option */
    .panel-title a.accordion-toggle {

        &:before,
        &.collapsed:before {
            color: $white;
        }
    }

    /*--------panel----------*/
    .expanel {
        background-color: transparent !important;
        border-color: $transparent-border  !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
    }

    .expanel-default>.expanel-heading {
        background-color: $transparent-theme  !important;
        border-color: $transparent-theme  !important;
    }

    .expanel-heading {
        border-bottom-color: $transparent-border;
    }

    .expanel-footer {
        background-color: $transparent-theme  !important;
        border-top-color: $transparent-border  !important;
    }

    .popover {
        background-color: $transparent-body;
        border-color: #dee3eb;
        filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
    }

    .bs-popover-top .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before {
        border-top-color: rgba(0, 0, 0, 0.25);
    }

    .bs-popover-top .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
        border-top-color: $transparent-body;
    }

    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
        border-right-color: #dee3eb;
    }

    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        border-right-color: transparent;
    }

    .bs-popover-bottom .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
        border-bottom-color: #dee3eb;
    }

    .bs-popover-bottom .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
        border-bottom-color: transparent;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        border-bottom-color: transparent;
    }

    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
        border-left-color: #dee3eb;
    }

    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        border-left-color: $transparent-theme;
    }

    .popover-header {
        color: inherit;
        background-color: $transparent-theme;
        border-bottom-color: #ebebeb;
    }

    .popover-body {
        color: $white;
    }

    /*-----progress-----*/
    .progress {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .progress-bar {
        color: $white;
    }

    /*------ Tables -----*/
    .table {
        color: $white;

        thead th {
            border-bottom-color: $transparent-border;
        }
    }

    .text-wrap table thead th {
        border-bottom-color: $transparent-border;
    }

    .table tbody+tbody,
    .text-wrap table tbody+tbody {
        border-top: 2px solid $transparent-border;
    }

    .table .table,
    .text-wrap table .table,
    .table .text-wrap table {
        background-color: $transparent-theme;
    }

    .text-wrap {

        .table table,
        table table {
            background-color: $transparent-theme;
        }
    }

    .table-bordered,
    .text-wrap table,
    .table-bordered th,
    .text-wrap table th,
    .table-bordered td,
    .text-wrap table td {
        border-color: $transparent-border;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.02);
    }

    .table-hover tbody {

        tr:hover,
        th {
            background-color: $transparent-body;
            color: $white;
        }
    }

    .table-active {
        background-color: rgba(0, 0, 0, 0.04);

        > {

            th,
            td {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }

    .table-hover .table-active:hover {
        background-color: rgba(0, 0, 0, 0.04);

        > {

            td,
            th {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }

    .table .thead-dark th,
    .text-wrap table .thead-dark th {
        color: $white;
        background-color: $white;
        border-color: #32383e;
    }

    .table .thead-light th,
    .text-wrap table .thead-light th {
        color: #495057;
        background-color: $transparent-theme;
        border-color: $transparent-border;
    }

    .table-inbox {
        border-color: $transparent-border;

        tr {
            border-bottom-color: rgba(238, 238, 238, 0.7);

            &:last-child {
                border-bottom-color: $transparent-border;
            }

            td .fa-star {

                &.inbox-started,
                &:hover {
                    color: #f78a09;
                }
            }

            &.unread td {
                background: $transparent-theme;
            }
        }
    }

    .table th,
    .text-wrap table th {
        color: $white;
    }

    .table .table-light th,
    .transparent-mode .text-wrap table th {
        color: $default-color;
    }

    .table-vcenter {

        td,
        th {
            border-top-color: $transparent-border;
        }
    }

    .table-secondary {

        tbody+tbody,
        td,
        th,
        thead th {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }

    .table-primary {

        tbody+tbody,
        td,
        th,
        thead th {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }

    .table-striped tbody tr {
        &:nth-of-type(odd) {
            color: $white;
        }

        &:nth-of-type(even) {
            background-color: $transparent-theme;
        }
    }

    .table-calendar-link {
        background: #f8f9fa;
        color: #495057;

        &:before {
            background: #467fcf;
        }

        &:hover {
            color: $white;
            background: #467fcf;

            &:before {
                background: $transparent-theme;
            }
        }
    }

    .table-header:hover,
    .table-header-asc,
    .table-header-desc {
        color: #495057 !important;
    }

    .table {
        > {
            :not(:last-child)> :last-child>* {
                border-bottom-color: $transparent-border;
            }
        }

        tbody td {
            border-color: $transparent-border;
        }
    }

    .table-hover>tbody>tr:hover>* {
        color: $text-color;
    }

    .table> :not(:first-child) {
        border-top-color: $transparent-border;
    }

    .table-striped>tbody>tr:nth-of-type(odd)>* {
        color: $white;
    }

    #data-table .text-primary span {
        color: #b7b7d3;
    }

    /*---- Tags-----*/
    .tag {
        color: $white;
        background-color: $transparent-body;
    }

    a.tag:hover {
        background-color: rgba(110, 118, 135, 0.2);
        color: inherit;
    }

    .tag-addon {
        color: $white;
    }

    .tab-content i,
    .tabs-menu2 ul li .active {
        color: $white;
    }

    a.tag-addon:hover {
        background: inherit;
        color: inherit;
    }

    .tag-blue {
        background-color: #467fcf !important;
        color: $white;
    }

    .tag-indigo {
        background-color: $indigo  !important;
        color: $white;
    }

    .tag-purple {
        background-color: #867efc !important;
        color: $white;
    }

    .tag-pink {
        background-color: #ec82ef !important;
        color: $white;
    }

    .tag-red {
        background-color: #fb7d92 !important;
        color: $white;
    }

    .tag-orange {
        background-color: $orange  !important;
        color: $white;
    }

    .tag-yellow {
        background-color: #ecb403 !important;
        color: $white;
    }

    .tag-green {
        background-color: $green  !important;
        color: $white;
    }

    .tag-teal {
        background-color: #2bcbba !important;
        color: $white;
    }

    .tag-cyan {
        background-color: #17a2b8 !important;
        color: $white;
    }

    .tag-white {
        background-color: $transparent-theme;
        color: $white;
    }

    .tag-gray {
        background-color: #868e96 !important;
        color: $white;
    }

    .tag-gray-dark {
        background-color: #414160;
        color: $white;
    }

    .tag-azure {
        background-color: $azure  !important;
        color: $white;
    }

    .tag-lime {
        background-color: $lime  !important;
        color: $white;
    }

    .tag-primary {
        background-color: #467fcf;
        color: $white;
        background-color: $primary-1  !important;
        color: $white;
    }

    .tag-secondary {
        background-color: #868e96;
        color: $white;
    }

    .tag-success {
        background-color: $green  !important;
        color: $white;
    }

    .tag-info {
        background-color: $azure;
        color: $white;
    }

    .tag-warning {
        background-color: #ecb403 !important;
        color: $white;
    }

    .tag-danger {
        background-color: #fb7d92 !important;
        color: $white;
    }

    .tag-light {
        background-color: #f8f9fa;
        color: $white;
    }

    .tag-dark {
        background-color: #25253e;
        color: $white;
    }

    .tag-round::before {
        background-color: $white;
    }

    .tag-outline-info {
        background-color: #c7e0fd;
        color: $info;
        border-color: $info;

        &::before {
            border-color: $info;
        }
    }

    .tag-outline {
        border-color: $transparent-border;
    }

    .tag-border {
        border-color: $transparent-border;
        background-color: transparent;
    }

    /*---------Thumbnails----------*/
    .thumbnail {
        background-color: $transparent-theme;
        border-color: $transparent-border;
    }

    .bs-popover-top .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
        border-top-color: $transparent-body;
    }

    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        border-right-color: $transparent-body;
    }

    .bs-popover-bottom .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
        border-bottom-color: transparent;
    }

    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        border-left-color: $transparent-body;
    }

    .tooltip-static-demo {
        background-color: $transparent-body;
    }

    .popover-static-demo {
        background-color: $transparent-body;
        border-color: $transparent-border;
    }

    .tooltip-primary {
        .tooltip-inner {
            background-color: $primary-1  !important;
            color: $white;
        }

        &.bs-tooltip-top .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
            border-top-color: $primary-1;
        }

        &.bs-tooltip-bottom .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
            border-bottom-color: $primary-1;
        }

        &.bs-tooltip-start .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
            border-left-color: $primary-1;
        }

        &.bs-tooltip-end .tooltip-arrow::before,
        &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
            border-right-color: $primary-1;
        }
    }

    .popover {
        background-color: $transparent-body;
        border: 0px solid $transparent-theme;
    }

    .bs-popover-top>.popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
        border-top-color: $transparent-body;
    }

    .bs-popover-top>.popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
        border-top-color: $transparent-body;
    }

    .bs-popover-end>.popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
        border-right-color: $transparent-body;
    }

    .bs-popover-end>.popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
        border-right-color: $transparent-body;
    }

    .bs-popover-bottom>.popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
        border-bottom-color: transparent;
    }

    .bs-popover-bottom>.popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
        border-bottom-color: $transparent-theme;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        border-bottom-color: $transparent-border;
    }

    .bs-popover-start>.popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: $transparent-body;
    }

    .bs-popover-start>.popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: $transparent-body;
    }

    .popover-header {
        color: $white;
        background-color: $transparent-theme;
        border-color: $transparent-border;
    }

    .popover-body {
        color: $white-5;
    }

    .popover-head-primary {
        .popover-header {
            color: $white;
            background-color: $primary-1  !important;
        }

        &.bs-popover-bottom .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
            border-bottom-color: $primary-1  !important;
        }
    }

    .popover-head-secondary {
        .popover-header {
            color: $white;
            background-color: $secondary  !important;
        }

        &.bs-popover-bottom .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
            border-bottom-color: $secondary  !important;
        }
    }

    .popover-head-primary .popover-body,
    .popover-head-secondary .popover-body {
        border-color: rgba(20, 17, 45, 0.2);
    }

    .popover-primary {
        background-color: $primary-1  !important;

        .popover-header {
            background-color: $primary-1  !important;
            border-bottom-width: 0 !important;
            color: $white;
        }

        &.bs-popover-top .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
            border-top-color: $primary-1  !important;
        }

        &.bs-popover-bottom .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
            border-bottom-color: $primary-1  !important;
        }

        &.bs-popover-start .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: $primary-1  !important;
        }

        &.bs-popover-end .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: $primary-1  !important;
        }
    }

    /*  ######## CUSTOM-STYLES ######## */
    /*----- Custom control -----*/
    .custom-control-input {
        &:checked~.custom-control-label::before {
            color: $white;
        }

        &:active~.custom-control-label::before {
            color: $white;
            background-color: rgba(218, 201, 232, 0.5);
        }

        &:disabled~.custom-control-label {
            color: $white-7;

            &::before {
                background-color: $transparent-border;
            }
        }
    }

    .custom-control-label::before {
        background-color: $transparent-border;
    }

    .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
        background-color: rgba(212, 182, 228, 0.5);
    }

    .form-select {
        color: $white-7;
        border-color: $transparent-border;
        background: transparent;

        &:focus {
            border-color: $transparent-border;
            box-shadow: none;

            &::-ms-value {
                color: $white-7;
                background-color: $transparent-theme;
            }
        }

        &:disabled {
            color: #9fa7af;
            background-color: $transparent-theme;
        }
    }

    .form-file-label {
        color: $white-7;
        background-color: $transparent-theme;
        border-color: $transparent-border;

        &::after {
            color: $white;
            border-left-color: $transparent-border;
        }
    }

    .form-range {
        &::-webkit-slider-thumb {
            background: $transparent-theme;
            box-shadow: none;

            &:focus {
                box-shadow: 0 0 0 1px $transparent-theme, 0 0 0 2px rgba(98, 58, 162, 0.25);
            }

            &:active {
                background-color: #d4e1f4;
            }
        }

        &::-webkit-slider-runnable-track {
            background-color: $transparent-border;
            background: #467fcf;
        }

        &::-moz-range-thumb {
            background: $transparent-theme;

            &:focus {
                box-shadow: 0 0 0 1px $transparent-theme, 0 0 0 2px rgba(98, 58, 162, 0.25);
            }

            &:active {
                background-color: #d4e1f4;
            }
        }

        &::-moz-range-track {
            background-color: $transparent-border;
            background: rgba(0, 50, 126, 0.12);
        }

        &::-ms-thumb {
            background: $transparent-theme;
            border-color: rgba(0, 30, 75, 0.12);

            &:focus {
                box-shadow: 0 0 0 1px $transparent-theme, 0 0 0 2px rgba(98, 58, 162, 0.25);
            }

            &:active {
                background-color: #d4e1f4;
            }
        }

        &::-ms-fill-lower {
            background-color: $transparent-border;
            background: #467fcf;
        }
    }

    .custom-control-label:before {
        border-color: $transparent-border;
        background-color: rgba(255, 255, 255, 0.02);
    }

    .form-range {
        &:focus {

            &::-webkit-slider-thumb,
            &::-moz-range-thumb,
            &::-ms-thumb {
                border-color: #467fcf;
                background-color: #467fcf;
            }
        }

        &::-moz-range-progress {
            background: #467fcf;
        }

        &::-ms-fill-upper {
            background: rgba(0, 50, 126, 0.12);
            background-color: $transparent-border;
        }
    }

    .custom-switch-description {
        color: $white-7;
    }

    .custom-switch-input:checked~.custom-switch-description {
        color: $white-7;
    }

    .custom-switch-indicator {
        background: $white-5;
        border-color: $transparent-border;

        &:before {
            background: $white;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
        }
    }

    .custom-switch-input:focus~.custom-switch-indicator {
        border-color: #60529f;
    }

    .custom-radio-md .custom-control-label::before,
    .custom-radio-lg .custom-control-label::before {
        background-color: $transparent-border;
    }

    .custom-checkbox-md,
    .custom-checkbox-lg {
        .custom-control-label::before {
            background-color: $transparent-border;
        }

        .custom-control-input:disabled:indeterminate~.custom-control-label::before {
            background-color: rgba(212, 182, 228, 0.5);
        }
    }

    .custom-switch-input:disabled~.custom-switch-indicator {
        background: #e9ecef;
    }

    .custom-switch-input:checked~.custom-switch-indicator {
        background: $primary-1;
    }

    .custom-switch-indicator-md,
    .custom-switch-indicator-lg {
        background: $white-5;
        border-color: $transparent-border;

        &::before {
            background: $white;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
        }
    }

    .collapse:not(.show) {
        background: $transparent-theme;
    }

    .notifyimg {
        color: $white;
    }

    /*----- Global Loader -----*/
    .aside {
        background: $transparent-theme;
        border-left-color: $transparent-border;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    }

    @media (max-width: 992px) {
        .about-con {
            border-bottom-color: $transparent-border;
        }
    }

    @media (max-width: 480px) {

        .tabs-menu ul li a,
        .tabs-menu1 ul li {
            border-color: $transparent-border;
        }
    }

    @media (max-width: 320px) {
        .construction .btn.btn-icon {
            color: $white;
        }
    }

    @media (max-width: 360px) {
        .breadcrumb {
            color: $white;
        }
    }

    @media (max-width: 768px) {
        .richText .richText-toolbar ul li a {
            border-color: $transparent-border;
        }

        .richText .richText-toolbar ul li {
            border-bottom: $transparent-border solid 1px;
        }

        .richText .richText-toolbar {
            border-bottom: 0 !important;
        }
    }

    .stamp {
        color: $white;
        background: #868e96;
    }

    .example {
        border-color: $transparent-border;
    }

    .example-bg {
        background: transparent;
    }

    .colorinput-color {
        border: 3px solid $transparent-border;
        color: $white;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }

    .colorinput-input:focus~.colorinput-color {
        border-color: $transparent-border;
        box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
    }

    #back-to-top {
        &:hover {
            background: $primary-1  !important;
            color: $white  !important;
        }
    }

    .features span {
        color: #43414e;
    }

    .feature .border {
        color: $white;
    }

    .actions:not(.a-alt)>li>a>i {
        color: #939393;
    }

    /* --------Added--------- css*/
    #sidebar li a.active {
        background: $transparent-theme;

        a[data-toggle="collapse"] {
            background: $transparent-theme;
        }
    }

    /* line 77, C:/wamp/www/github/addSlider/src/partials/_addSlider.scss */
    .line-divide {
        border-color: rgba(218, 216, 219, 0.2);
    }

    .activity {
        border-left-color: rgba(0, 0, 0, 0.125);
    }

    .username {
        color: $white;

        +p {
            color: #f2f2f2;
        }
    }

    #user-profile {
        .profile-details ul>li>span {
            color: #643ba2;
        }
    }

    @media (max-width: 1024px) {
        body {

            &.search-show:before,
            &.sidebar-show:before {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }

    @media (max-width: 575.98px) {
        .header .form-inline .search-element .form-control {
            background: $transparent-theme;
        }

        .form-control.header-search {
            color: $black;
        }

        .header {
            .form-inline {
                .btn {
                    color: #46494a !important;
                }

                .form-control::-webkit-input-placeholder {
                    color: $white  !important;
                }
            }

            .navsearch i {
                color: $white;
            }
        }
    }

    .settings {
        color: $white;
    }

    .member {
        background: $transparent-theme;
        border-color: $transparent-border;
    }

    @media screen and (max-width: 998px) and (min-width: 768px) {

        .note-popover .popover-content,
        .card-header.note-toolbar {
            background: $transparent-theme;
        }
    }

    .material-switch> {
        label {
            &::before {
                background: $black-5;
                box-shadow: inset 0px 0px 10px rgba(42, 38, 53, 0.5);
            }

            &::after {
                background: $transparent-body;
                box-shadow: 0px 0px 5px rgba(42, 38, 53, 0.9);
            }
        }

        input[type="checkbox"]:checked+label {

            &::before,
            &::after {
                background: inherit;
            }
        }
    }

    .sw-theme-default>ul.step-anchor>li>a::after {
        background: none !important;
    }

    .border-transparet {
        border-color: rgba(255, 255, 255, 0.1);
    }

    .breadcrumb .breadcrumb-item.active,
    .breadcrumb-item1 a {
        color: $white;
    }

    .input-group.input-indec .form-control {
        border-color: $transparent-border;
    }

    /*********************IE***************************/
    @media (min-width: 992px) {
        .main-header-center .form-control {
            background: transparent;
            border-color: rgba(255, 255, 255, 0.2);
        }
    }

    @media (min-width: 992px) {
        .main-header-center .btn {
            background-color: transparent;
            color: $white-8;
        }
    }

    .pulse-danger {
        background: #ee335e;

        &:before {
            background: rgba(238, 51, 94, 0.8);
            box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
        }
    }

    .pulse {
        background: #22c03c;

        &:before {
            background: rgba(34, 192, 60, 0.6);
            box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
        }
    }

    .progress {
        &.info1 {
            background: #fed5db;
        }

        &.secondary {
            background: #f9d1bd;
        }
    }

    .avatar {
        color: $white;
    }

    .badge-success-light {
        background-color: rgba(19, 191, 27, 0.15) !important;
        color: #13bf1b;
    }

    .badge-orange-light {
        color: #e17626;
        background-color: rgba(225, 118, 38, 0.15);
    }

    .badge-danger-light {
        color: #f33819;
        background-color: rgba(243, 56, 25, 0.15);
    }

    .badge.bg-white {
        background-color: $white  !important;
    }

    .browser-stats img {
        background: $transparent-theme;
    }

    .box-shadow-primary {
        box-shadow: 0 5px 10px $transparent-theme;
    }

    .box-shadow-secondary {
        box-shadow: 0 5px 10px $transparent-theme;
    }

    .box-shadow-success {
        box-shadow: 0 5px 10px $transparent-theme;
    }

    .box-shadow-danger {
        box-shadow: 0 5px 10px $transparent-theme;
    }

    .box-shadow-pink {
        box-shadow: 0 5px 10px $transparent-theme;
    }

    .sidebar {
        .tabs-menu ul li .active {
            color: $white;
            background-color: $black-1;
        }

        .feeds.avatar-circle.bg-primary-transparent::before {
            background: $white-3;
        }

        .feeds.avatar-circle-info::before {
            background: #0151b3;
        }

        .feeds.avatar-circle-info {
            background: rgba(142, 193, 255, 0.1) !important;

            .text-info {
                color: #0b54af;
            }
        }
    }

    .sidebar-right .dropdown-item h6 {
        color: $default-color;
    }

    .sidebar-right .panel-primary.card {
        background: transparent;
    }

    .sidebar-right .feeds.avatar-circle .text-primary {
        color: $white-5  !important;
    }

    .sidebar-right .feeds.avatar-circle.bg-primary-transparent {
        background-color: $white-05;
    }

    .nav.panel-tabs {
        a.active {
            color: $white;
        }

        &.panel-secondary a {
            &.active {
                background-color: $secondary  !important;
                color: $white;
            }

            color: $secondary;
        }

        &.panel-danger a {
            &.active {
                background-color: $danger  !important;
                color: $white;
            }

            color: $danger;
        }

        &.panel-success a {
            color: $success;

            &.active {
                background-color: $success  !important;
                color: $white;
            }
        }

        &.panel-info a {
            color: #538ed7;

            &.active {
                background-color: $info  !important;
                color: $white;
            }
        }
    }

    .task-list {
        color: $white;

        &:before {
            border-left-color: $white-2;
        }
    }

    .mail-inbox .icons {
        color: $white  !important;
    }

    .table-inbox tr td {
        i {
            color: $white-2;

            &:hover {
                color: #f7284a;
            }
        }

        .fa-star:hover {
            color: #fbc518;
        }
    }

    .mail-option {

        .btn-group a.btn,
        .chk-all {
            border-color: $transparent-border;
            color: $white;
        }

        .btn-group a.all {
            box-shadow: none;
        }
    }

    .inbox-pagination a.np-btn {
        border-color: $transparent-border;
        color: $white-7;
    }

    .acc-header a {
        &.collapsed {
            border-color: $transparent-border;
        }

        background-color: $transparent-theme;
        border-color: $transparent-border;
    }

    .acc-body {
        border-color: $transparent-border;
    }

    .card-pay .tabs-menu li a {
        &.active {
            background: $primary-1;
            color: $white;
        }

        border-color: $transparent-border;
        color: $white;
    }

    .main-content-label,
    .card-table-two .card-title,
    .card-dashboard-eight .card-title {
        color: $white;
    }

    .social-login {
        background: $transparent-theme;
        border-color: $transparent-border;
    }

    .user-social-detail .social-profile {
        background: $transparent-theme;
    }

    .txt1 {
        color: $white-7;
    }

    .hor-header.sticky.stickyClass .horizontal-main.hor-menu {
        box-shadow: 5px 7px 26px -5px rgba(104, 113, 123, 0.1);
    }

    .mini-stat-icon {
        color: $white;
    }

    .product-grid6 {
        overflow: hidden;

        .price span {
            color: $white-7;
        }

        .icons-wishlist li a {
            i {
                color: inherit;
            }

            &:hover,
            &:after,
            &:before {
                color: $white;
            }
        }

        .icons li a {
            i {
                color: inherit;
            }

            &:hover,
            &:after,
            &:before {
                color: $white;
            }
        }
    }

    .apexcharts-radialbar-track.apexcharts-track path {
        stroke: $transparent-theme;
    }

    .apex-charts text {
        fill: #000200;
    }

    /*--- Offcanvas ---*/
    .offcanvas {
        background: $transparent-body;
    }

    .offcanvas-start {
        border-right-color: $transparent-border;
    }

    .offcanvas-end {
        border-left-color: $transparent-border;
    }

    .offcanvas-bottom {
        border-top-color: $transparent-border;
    }

    .offcanvas-top {
        border-bottom-color: $transparent-border;
    }

    /*--Toast ---*/
    .toast {
        background: $transparent-theme;
        border-color: $transparent-border;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);

        .toast-header {
            background: $transparent-theme;
            color: $white;
            border-bottom-color: $transparent-border;
        }
    }

    .toast.show {
        .btn-close {
            color: #fff;
        }
    }

    .task-icon1:first-child {
        border: 2px solid $white-5;
    }

    .nav.product-sale a.active {
        background-color: $transparent-theme;
        border-bottom: none;
    }

    .profile-cover__img {
        color: $white;

        .profile-img-1>img {
            border: 5px solid $white;
        }

        >.h3 {
            color: #393939;
        }
    }

    .profile-cover__info .nav li {
        color: #464461;
    }

    .social.social-profile-buttons .social-icon {
        background: $transparent-body;
        border-color: $transparent-border;
        color: $text-color  !important;
    }

    .profile-share {
        border-color: $transparent-border;
        background: $transparent-theme;
    }

    .option-dots {

        &:focus,
        &:hover,
        &[aria-expanded="true"] {
            background: $transparent-theme;
        }

        color: $white-7;
    }

    @media (min-width: 601px) {
        .social-profile-buttons .nav {
            color: #999;
        }
    }

    .social-profile-buttons .nav li {
        color: #464461;
    }

    .item2-gl-menu {
        border-color: $transparent-border;

        li {
            a {
                color: $white-5;
            }

            .active {
                color: $white;
            }
        }
    }

    .product-label {
        background: rgba(178, 177, 183, 0.1);
    }

    .ui-widget-header,
    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
        background: $transparent-body;
    }

    .ui-state-hover,
    .ui-widget-content .ui-state-hover,
    .ui-widget-header .ui-state-hover,
    .ui-state-focus,
    .ui-widget-content .ui-state-focus,
    .ui-widget-header .ui-state-focus {
        border-color: $primary-1  !important;
        background: $primary-1  !important;
    }

    .ui-widget-content {
        background: $transparent-theme;
        border-color: $transparent-border;
    }

    .product-list .icons li a {

        &:after,
        &:before {
            color: $white;
        }
    }

    .product-grid6 .card-footer .btn-outline-primary:hover {
        color: $white;
    }

    .carousel-inner .carousel-item .thumb {
        &.active {
            border-color: $transparent-border;
        }

        border-color: $transparent-border;
    }

    .customer-services {
        span {
            background-color: transparent;
            color: $primary-1;
        }

        border-color: $transparent-border;
    }

    .login-social-icon {

        &::before,
        &::after {
            background-color: $transparent-border;
        }

        span {
            background: transparent;
        }
    }

    .custom-layout {
        color: $white;

        .nav-link.icon {
            i {
                color: $white  !important;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                background: rgba(255, 255, 255, 0.08);
            }

            i::after {
                background-color: transparent;
            }
        }
    }

    .transparent-mode .custom-layout .nav-link.icon i {
        color: $white  !important;
    }

    .country-selector .nav-link {
        color: #495046;
    }

    .theme-container .active {
        border-color: $primary-1;
        background: transparent;
        color: $primary-1;
    }

    .theme-container1 .active {
        border-color: $pink;
        background: transparent;
        color: $pink;
    }

    .theme-container2 .active {
        border-color: $secondary;
        background: transparent;
        color: $secondary;
    }

    .settings-icon {
        border-color: $primary-1;
    }

    .input-group-text.input-text-color {
        background-color: $transparent-theme;
    }

    .payment-icon {
        &.active svg {
            fill: $white;
        }

        svg {
            fill: $white;
        }
    }

    .notification {
        &:before {
            background: $white-1;
        }

        .notification-time {

            .date,
            .time {
                color: $white-8;
            }
        }

        .notification-icon a {
            background: $transparent-body;
            color: $white;
            border: 3px solid $white-5;
        }

        .notification-body {
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
            background: $transparent-theme;

            &:before {
                border: 10px solid transparent;
                border-right-color: $transparent-theme;
            }
        }
    }

    .notification-time-date {
        color: $white-3;
    }

    .btn-country {
        border-color: $transparent-border;
        box-shadow: none !important;
        box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
        color: $white;

        &:hover {
            border-color: $primary-1  !important;
            box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
        }
    }

    .btn-check:checked+.btn-country {
        border-color: $primary-1  !important;
        background: $transparent-theme;
    }

    .btn-country {

        &.active,
        &:active {
            border-color: $primary-1  !important;
        }
    }

    .email-icon {
        border-color: $transparent-border;
        color: #5c678f;
    }

    .product-grid6 .card-footer .btn-outline-primary:hover .wishlist-icon {
        color: $white;
    }

    .btn-outline-primary {
        .wishlist-icon {
            color: $white-6  !important;
        }

        &:hover .wishlist-icon {
            color: $white  !important;
        }
    }

    #chartZoom .btn-outline-primary:hover {
        color: $white;
    }

    .c3-legend-item text {
        fill: $white-5;
    }

    .file-image .icons li a {
        color: $white;

        &:after,
        &:before,
        &:hover {
            color: $white;
        }
    }

    .file-name {
        color: $white;
    }

    .img-1 img {
        border: 6px solid rgba(225, 225, 225, 0.5);
    }

    .profile-img {
        border-color: rgba(167, 180, 201, 0.2);
        background: rgba(225, 225, 225, 0.2);
    }

    /*-----Gallery-----*/
    .demo-gallery {
        >ul>li {
            a {
                border: 3px solid $white;
            }
        }

        &.dark>ul>li a {
            border: 3px solid #04070a;
        }
    }

    .gallery a img {
        border-color: rgba(0, 0, 0, 0.2);
    }

    .example+.highlight {
        border-top: none;
    }

    .highlight {
        border-color: $transparent-border;
        border-top: none;
        background: $transparent-theme;

        .hll {
            background-color: #ffc;
        }

        .c {
            color: #999;
        }

        .k {
            color: #069;
        }

        .o {
            color: #555;
        }

        .cm {
            color: #999;
        }

        .cp {
            color: #099;
        }

        .c1,
        .cs {
            color: #999;
        }

        .gd {
            background-color: #fcc;
            border-color: #c00;
        }

        .gr {
            color: #f00;
        }

        .gh {
            color: #030;
        }

        .gi {
            background-color: #cfc;
            border-color: #0c0;
        }

        .go {
            color: #aaa;
        }

        .gp {
            color: #009;
        }

        .gu {
            color: #030;
        }

        .gt {
            color: #9c6;
        }

        .kc,
        .kd,
        .kn,
        .kp,
        .kr {
            color: #069;
        }

        .kt {
            color: #078;
        }

        .m {
            color: #f60;
        }

        .s {
            color: #cc0099;
        }

        .na {
            color: #00cc7a;
        }

        .nb {
            color: #366;
        }

        .nc {
            color: #0a8;
        }

        .no {
            color: #360;
        }

        .nd {
            color: #99f;
        }

        .ni {
            color: #999;
        }

        .ne {
            color: #c00;
        }

        .nf {
            color: #c0f;
        }

        .nl {
            color: #99f;
        }

        .nn {
            color: #0cf;
        }

        .nt {
            color: #800000;
        }

        .nv {
            color: #033;
        }

        .ow {
            color: $black;
        }

        .w {
            color: #bbb;
        }

        .mf,
        .mh,
        .mi,
        .mo {
            color: #f60;
        }

        .sb,
        .sc,
        .sd,
        .s2,
        .se,
        .sh {
            color: #c30;
        }

        .si {
            color: #a00;
        }

        .sx {
            color: #c30;
        }

        .sr {
            color: #3aa;
        }

        .s1 {
            color: #c30;
        }

        .ss {
            color: #fc3;
        }

        .bp {
            color: #366;
        }

        .vc,
        .vg,
        .vi {
            color: #033;
        }

        .il {
            color: #f60;
        }

        .css {
            .o {
                color: #999;

                +.nt {
                    color: #999;
                }
            }

            .nt+.nt {
                color: #999;
            }
        }

        .language-bash::before,
        .language-sh::before,
        .language-powershell::before {
            color: #009;
        }
    }

    .label-default {
        background: #d5e0ec;
        color: $white;
    }

    .label-success {
        background: $success;
        color: $white;
    }

    .label-danger {
        background: #f5334f;
        color: $white;
    }

    .label-warning {
        background: $warning;
        color: $white;
    }

    .label-info {
        background: $info;
        color: $white;
    }

    /*-----Lists-----*/
    .list-group-item.active {
        background-color: $transparent-theme;
        color: $white;
        border-color: $transparent-border;
    }

    .list-group-item-action {
        color: $white-7;

        &:hover,
        &:focus,
        &:active {
            color: $white-7;
            background-color: $transparent-body;
        }
    }

    .list-group-item,
    .listorder,
    .listorder1,
    .listunorder,
    .listunorder1 {
        background-color: transparent;
        border-color: $transparent-border;
        color: $white-7;
    }

    .list-group-item {

        &.disabled,
        &:disabled {
            color: $white-2;
            background-color: transparent;
        }
    }

    .list-group-item-primary {
        color: #24426c !important;
        background-color: #cbdbf2;

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: #24426c;
                background-color: #b7cded;
            }

            &.active {
                color: $white;
                background-color: #24426c;
                border-color: #24426c;
            }
        }
    }

    .list-group-item-secondary {
        color: #464a4e !important;
        background-color: #dddfe2;

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: #464a4e;
                background-color: #cfd2d6;
            }

            &.active {
                color: $white;
                background-color: #464a4e;
                border-color: #464a4e;
            }
        }
    }

    .list-group-item-success {
        color: $success  !important;
        background-color: rgba(9, 173, 149, 0.4);

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: $success;
                background-color: rgba(9, 173, 149, 0.2);
            }

            &.active {
                color: $white;
                background-color: $success;
                border-color: $success;
            }
        }
    }

    .list-group-item-info {
        color: $info  !important;
        background-color: rgba(17, 112, 228, 0.4);

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: $info;
                background-color: rgba(17, 112, 228, 0.2);
            }

            &.active {
                color: $white;
                background-color: rgba(17, 112, 228, 0.2);
                border-color: rgba(17, 112, 228, 0.2);
            }
        }
    }

    .list-group-item-warning {
        color: $warning  !important;
        background-color: rgba(247, 183, 49, 0.4);

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: $warning;
                background-color: rgba(247, 183, 49, 0.2);
            }

            &.active {
                color: $white;
                background-color: $warning;
                border-color: $warning;
            }
        }
    }

    .list-group-item-danger {
        color: $danger  !important;
        background-color: rgba(232, 38, 70, 0.4);

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: $danger;
                background-color: rgba(232, 38, 70, 0.2);
            }

            &.active {
                color: $white;
                background-color: $danger;
                border-color: $danger;
            }
        }
    }

    .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: #818182;
                background-color: #ececf6;
            }

            &.active {
                color: $white;
                background-color: #818182;
                border-color: #818182;
            }
        }
    }

    .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;

        &.list-group-item-action {

            &:hover,
            &:focus {
                color: #1b1e21;
                background-color: #b9bbbe;
            }

            &.active {
                color: $white;
                background-color: #1b1e21;
                border-color: #1b1e21;
            }
        }
    }

    .list-group-item {
        border-color: $transparent-border;
        color: $white-7;

        &.active .icon {
            color: inherit !important;
        }

        .icon {
            color: $white  !important;
        }
    }

    .list-group-transparent {
        .list-group-item {
            &.active {
                background: $transparent-theme;
                color: $white;
            }
        }

        &.file-manager .list-group-item {
            color: $white;
        }
    }

    .file-radius-attachments i {
        color: $text-color;
    }

    .file-square-attachments a {
        color: $text-color;
    }

    .file-image-1 {
        border-color: $transparent-border;
    }

    .file-image-1 .file-name-1 {
        color: $text-color;
    }

    .list-group-transparent.file-manager.file-manager-border .list-group-item {
        border-color: $transparent-border;
    }

    /*------ Media object ------*/
    .btn-close {
        color: $white-7;

        &:hover,
        &:focus {
            color: inherit;
        }
    }

    .navbar-toggler {
        background-color: transparent;
        border-color: transparent;
    }

    @media (min-width: 992px) {
        .responsive-navbar .navbar-collapse {
            background: transparent;
        }
    }

    a.icon:hover {
        color: #dcdfed !important;
    }

    .navbar-light {
        .navbar-brand {
            color: $white;

            &:hover,
            &:focus {
                color: $white;
            }
        }

        .navbar-nav {
            .nav-link {
                color: rgba(0, 0, 0, 0.5);

                &:hover,
                &:focus {
                    color: rgba(0, 0, 0, 0.7);
                }

                &.disabled {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .show>.nav-link,
            .active>.nav-link {
                color: rgba(0, 0, 0, 0.9);
            }

            .nav-link {

                &.show,
                &.active {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }

        .navbar-toggler {
            color: rgba(0, 0, 0, 0.5);
            border-color: rgba(0, 0, 0, 0.1);
        }

        .navbar-text {
            color: rgba(0, 0, 0, 0.5);

            a {
                color: rgba(0, 0, 0, 0.9);

                &:hover,
                &:focus {
                    color: rgba(0, 0, 0, 0.9);
                }
            }
        }
    }

    .navbar-dark {
        .navbar-brand {
            color: $white;

            &:hover,
            &:focus {
                color: $white;
            }
        }

        .navbar-nav {
            .nav-link {
                color: rgba(255, 255, 255, 0.5);

                &:hover,
                &:focus {
                    color: rgba(255, 255, 255, 0.75);
                }

                &.disabled {
                    color: rgba(255, 255, 255, 0.25);
                }
            }

            .show>.nav-link,
            .active>.nav-link {
                color: $white;
            }

            .nav-link {

                &.show,
                &.active {
                    color: $white;
                }
            }
        }

        .navbar-toggler {
            color: rgba(255, 255, 255, 0.5);
            border-color: rgba(255, 255, 255, 0.1);
        }

        .navbar-text {
            color: rgba(255, 255, 255, 0.5);

            a {
                color: $white;

                &:hover,
                &:focus {
                    color: $white;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .navresponsive-toggler span {

            // color: $white;
            &:after {
                background: rgba($primary-1, 0);
            }
        }

        &.header-light {
            #navbarSupportedContent-4 {
                .nav-link.icon {
                    color: #fff !important;
                }
            }
        }

        &.dark-header {
            #navbarSupportedContent-4 {
                .nav-link.icon {
                    color: #fff !important;
                }
            }
        }

        &.dark-header {
            .navresponsive-toggler span {
                color: $white;
            }
        }
    }

    @media (max-width: 991px) {
        .responsive-navbar .navbar-collapse {
            background: $transparent-body;
            box-shadow: 0 12px 11px -3px rgba(0, 0, 0, 0.5);
            border-top-color: $transparent-border;
        }

        .responsive-navbar .navbar-collapse .icon.navsearch {
            border-color: #e4e6f9;
        }
    }

    /*--scrollspy ---*/
    .scrollspy-example {
        border-right-color: $transparent-border;
        border-left-color: $transparent-border;
        border-bottom-color: $transparent-border;
    }

    .scrollspy-example-2 {
        border-color: $transparent-border;
    }

    #navbar-example3 .nav-link {
        color: $white;

        &.active {
            color: $white;
        }
    }

    .nav-link {

        &:focus,
        &:hover {
            color: $primary-1;
        }
    }

    /*-----Pricing tables-----*/
    .pricing {
        color: $white;
    }

    .pricing1 {
        color: #707070;
    }

    .pricing {
        .list-unstyled li {
            border-bottom-color: rgba(255, 255, 255, 0.1);
        }
    }

    .pricing1 {
        .list-unstyled li {
            border-bottom-color: $transparent-border;
        }
    }

    .panel-heading-landing {
        background: #f7f7f7 !important;
        border: solid 2px #1643a3 !important;
        border-bottom: none !important;
    }

    /*------ Pricing Styles ---------*/
    .panel-heading {
        border-bottom-color: rgba(255, 255, 255, 0.2);
        background: $transparent-theme;
    }

    .panel.price {
        box-shadow: 0 0.15rem 1.75rem 0 $transparent-theme;
        background: $transparent-theme;

        >.panel-heading {
            color: $white;
        }
    }

    .price {
        .panel-footer {
            background-color: transparent;
        }

        &.panel-color>.panel-body {
            background-color: transparent;
        }
    }

    .ribbone1-price .ribbon span {
        color: $white;
        background: #79a70a;
        background: $primary-1;
        box-shadow: 0 3px 10px -5px black;

        &::before {
            border-left: 3px solid $primary-1;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $primary-1;
        }

        &::after {
            border-left: 3px solid transparent;
            border-right: 3px solid $primary-1;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $primary-1;
        }
    }

    .secondary .pricing-divider {
        background: transparent;
    }

    .danger .pricing-divider {
        background: transparent !important;
    }

    .primary .pricing-divider {
        background: transparent !important;
    }

    .success .pricing-divider {
        background: transparent;
    }

    .primary .princing-item .bg-white {
        background-color: transparent !important;
    }

    .secondary .princing-item .bg-white {
        background-color: transparent !important;
    }

    .danger .princing-item .bg-white {
        background-color: transparent !important;
    }

    .success .princing-item .bg-white {
        background-color: transparent !important;
    }

    /*-- rating--*/
    .rating-stars {
        input {
            color: #495057;
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }
    }

    .tabs-menu ul li {
        a {
            color: $white-7;
        }

        .active {
            color: $primary-1;
        }
    }

    .tabs-menu1 ul li a {
        color: $white;
    }

    .tab-menu-heading {
        border-bottom-color: $transparent-border  !important;
    }

    .tabs-menu2 ul li {
        a {
            color: #636262;
        }

        .fade {
            color: #eeee;
        }
    }

    .sidebar-right .tab-content i,
    .tabs-menu2 ul li .active {
        color: $white-7;
    }

    .search-tabs ul li a {
        &.active {
            border-bottom: 3px solid $primary-1;
            background-color: transparent !important;
        }

        &:hover {
            background-color: transparent !important;
        }
    }

    .tabs-menu-border ul li .active {
        border-color: $transparent-border;
    }

    .tabs-menu-boxed ul li {
        a {
            color: $white;
            border-bottom-color: $transparent-border;
        }

        .active {
            border-color: $transparent-border;
            border-bottom-color: transparent;
        }
    }

    /***** time-line*****/
    .timeline__item:after {
        background: $white  !important;
    }

    .timeline__content {
        background-color: $transparent-theme;
    }

    /*---- Time line -----*/
    .timeline:before {
        background-color: #e9ecef;
    }

    .timeline-item {

        &:first-child:before,
        &:last-child:before {
            background: $transparent-theme;
        }
    }

    .timeline-badge {
        border-color: $white;
        background: #adb5bd;
    }

    .timeline-time {
        color: #9aa0ac;
    }

    .timeline__item--right .timeline__content:before {
        border-right: 12px solid rgba(238, 232, 239, 0.9);
    }

    ul.timeline {
        &:before {
            background: #d4d9df;
        }

        >li:before {
            border-color: #6c6c6f;
        }
    }

    /*----Timeline---*/
    .vtimeline::before {
        background-color: $transparent-border;
    }

    .vtimeline .timeline-wrapper {
        .timeline-panel {
            background: $transparent-theme;
            box-shadow: 0 5px 12px 0 rgb(16 19 41 / 60%);

            &:after {
                border-top: 10px solid transparent;
                border-left: 10px solid $transparent-border;
                border-right: 0 solid $transparent-border;
                border-bottom: 10px solid transparent;
            }
        }

        .timeline-badge {
            border: 2px solid $white-3;

            i {
                color: $white;
            }
        }

        &.timeline-inverted .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 10px;
        }
    }

    .timeline-wrapper-primary {

        .timeline-panel:before,
        .timeline-badge {
            background: $primary-1;
        }
    }

    .timeline-wrapper-secondary {

        .timeline-panel:before,
        .timeline-badge {
            background: $secondary;
        }
    }

    .timeline-wrapper-success {

        .timeline-panel:before,
        .timeline-badge {
            background: $success;
        }
    }

    .timeline-wrapper-green {

        .timeline-panel:before,
        .timeline-badge {
            background: $green;
        }
    }

    .timeline-wrapper-warning {

        .timeline-panel:before,
        .timeline-badge {
            background: #fcd539;
        }
    }

    .timeline-wrapper-danger {

        .timeline-panel:before,
        .timeline-badge {
            background: #f16d75;
        }
    }

    .timeline-wrapper-light {

        .timeline-panel:before,
        .timeline-badge {
            background: $transparent-theme;
        }
    }

    .timeline-wrapper-dark {

        .timeline-panel:before,
        .timeline-badge {
            background: #828db1;
        }
    }

    @media (max-width: 767px) {
        .vtimeline .timeline-wrapper .timeline-panel:after {
            border-right: 14px solid $white-2  !important;
            border-left: 0 solid $white-2  !important;
        }
    }

    /* ######## LAYOUT-STYLES ######## */
    .footer {
        background: transparent;
        border-top-color: rgba(255, 255, 255, 0.02);
        color: $white-8;

        a:not(.btn) {
            color: $white;
        }

        .social ul li a {
            border-color: $transparent-border;
            background: $transparent-body;
            color: $white;
        }
    }

    .top-footer {
        p {
            color: $white-7;
        }

        a {
            color: $white-7;

            address {
                color: $white-7;
            }
        }

        img {
            border-color: $transparent-border;

            &:hover {
                color: #8e9090;
            }
        }
    }

    .footer-payments a {
        color: #a7a8c7;
    }

    .main-footer {
        background-color: transparent;
        border-top-color: $transparent-border;
    }

    .header {
        background: $transparent-theme;
        border-bottom-color: $transparent-border;
    }

    .header-brand,
    .app-header .header-brand,
    .header-brand:hover {
        color: inherit;
    }

    .app-header.sticky.stickyClass {
        background-color: $primary-1;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 10%) !important;
    }

    @supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
        .app-header.sticky.stickyClass {
            background-color: transparent !important;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 10%) !important;
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
        }
    }

    @media (max-width: 991px) {
        .header.hor-header {
            border-bottom-color: $transparent-border;
            box-shadow: 0 8px 24px $transparent-theme;
        }

        .hor-header .header-brand-img.light-logo {
            margin: 0 auto;
            margin-top: 6px;
        }
    }

    .header {
        .form-inline {
            .form-control {
                border-color: rgba(225, 225, 225, 0.1);
                background: rgba(225, 225, 225, 0.3);
                color: $black  !important;
            }

            .btn {
                border: 2px solid transparent;
                box-shadow: none;
                background: transparent;
                color: $white;
            }
        }
    }

    @media (max-width: 767.98px) and (min-width: 576px) {
        .header .navsearch i {
            color: $white;
        }

        .search-element .form-control {
            background: $white  !important;
            color: $black;
        }

        .header {
            .form-inline .form-control::-webkit-input-placeholder {
                color: $white-7;
            }

            .navsearch i {
                color: $white;
            }

            .form-inline .btn {
                color: #46494a !important;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .animated-arrow span {

            &:before,
            &:after {
                background: $default-color;
            }
        }

        .animated-arrow span {

            &:before,
            &:after {
                background: $default-color;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        body {
            background-color: $transparent-theme;
        }
    }

    .hor-header .header-brand1 {
        color: inherit;
    }

    .header-right-icons {
        .nav-link.icon:hover {
            background: none;
        }

        .profile-user:hover {
            box-shadow: none;
        }
    }

    .hor-header .header-right-icons .nav-link.icon {
        color: $white;
    }

    .logo-horizontal .header-brand-img.desktop-logo {
        display: block !important;
    }

    .logo-horizontal .header-brand-img.light-logo1 {
        display: none;
    }

    .hor-header .header-brand-img.light-logo {
        display: block;
    }

    /*Logo-center header */
    @media (max-width: 992px) {
        .header {
            border-bottom-color: rgba(255, 255, 255, 0.2);
        }

        .header.hor-header {
            background-color: $primary-1;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.01) !important;
        }

        @supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
            .header.hor-header {
                background-color: transparent !important;
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.01) !important;
            }
        }
    }

    .header {
        .dropdown-menu {
            box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
            border-color: $transparent-border;

            .dropdown-item {
                border-bottom-color: $transparent-border;
            }
        }

        .dropdown-item,
        .notifications-menu h5,
        .message-menu h5 {
            color: $white;
        }

        .notifications-menu span,
        .message-menu span {
            color: $white-7;
        }

        .dropdown-menu {
            box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
            border-color: $transparent-border;

            .dropdown-item {
                border-bottom-color: $transparent-border;
            }
        }

        .profile-1 .dropdown-item .dropdown-icon {
            color: $primary-1;

            &::after {
                background: rgba($primary-1, 0);
            }
        }
    }

    .responsive-navbar {

        .notifications-menu h5,
        .message-menu h5 {
            color: $white;
        }

        .notifications-menu span,
        .message-menu span {
            color: $white-7;
        }

        .dropdown-menu {
            box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
            border-color: $transparent-border;

            .dropdown-item {
                border-bottom-color: $transparent-border;
            }
        }

        .profile-1 .dropdown-item .dropdown-icon {
            color: $white-7;

            &::after {
                background: rgba($primary-1, 0);
            }
        }
    }

    &.horizontal {
        .side-menu>li>a {
            color: $white-8;
        }

        .logo-horizontal .header-brand-img.light-logo1 {
            display: none;
        }
    }

    .ps__rail-y:hover>.ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: $transparent-theme;
    }

    /*==============================================================================
                                Start Mobile CSS
  ===============================================================================*/
    /* ================== Mobile Menu Change Brake Point ================== */
    @media only screen and (max-width: 991px) {

        /* ================== Mobile Slide Down Links CSS ================== */
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */
        .horizontal-header {
            border-bottom-color: rgba(0, 0, 0, 0.1);
            border-top-color: rgba(0, 0, 0, 0.1);
        }

        .callusbtn {
            color: #a9a9a9;

            &:hover .fa {
                color: #a9a9a9;
            }
        }

        /* Mobile Toggle Menu icon (X ICON) */
        .animated-arrow span {
            background: $white-7;

            &:before,
            &:after {
                background: $white-7;
            }
        }

        &.active .animated-arrow span {
            background-color: transparent;
        }

        /* ================== Mobile Overlay/Drawer CSS ================== */
        .horizontal-overlapbg {
            background-color: rgba(0, 0, 0, 0.45);
        }

        /*End Media Query*/
    }

    /* Extra @Media Query*/
    .horizontal-main.hor-menu {
        background: $transparent-theme;
        border-bottom-color: $transparent-border;
    }

    .icons-list-item {
        border-color: $transparent-border;

        i {
            color: $white-5;
        }
    }

    .browser {
        background: no-repeat center/100% 100%;
    }

    .flag,
    .payment {
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .col-sm-3 a {
        border-color: transparent;

        &:hover {
            border-color: #ff4647;
            background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
        }
    }

    /* ######## LIB-STYLES ######## */
    /*----- Date Picker ----*/
    .ui-datepicker {
        background-color: $transparent-body;
        border-color: $transparent-border;

        .ui-datepicker-header {
            color: $white;

            .ui-datepicker-next,
            .ui-datepicker-prev {
                text-indent: -99999px;
                color: $white-7;
            }

            .ui-datepicker-next {

                &:hover::before,
                &:focus::before {
                    color: $dark;
                }
            }

            .ui-datepicker-prev {

                &:hover::before,
                &:focus::before {
                    color: $dark;
                }
            }

            .ui-datepicker-next-hover,
            .ui-datepicker-prev-hover {
                color: $white-7;
            }
        }

        .ui-datepicker-calendar {
            th {
                color: $white-7;
            }

            td {
                border-color: $transparent-border;
                background-color: transparent;

                span {
                    background-color: $transparent-theme;
                    color: $white;
                }

                a {
                    background-color: $transparent-theme;
                    color: $white-7;

                    &:hover {
                        background-color: $transparent-theme;
                        color: $white;
                    }
                }
            }

            .ui-datepicker-today a {
                background-color: $transparent-theme;
                color: $white;
            }
        }

        .ui-datepicker-title {
            color: $white;
        }
    }

    .jvectormap-tip {
        background: $white;
        color: $black;
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        background: $transparent-body;
        color: $white;
    }

    .jvectormap-legend {
        background: $black;
        color: $white;
    }

    .select2-container--default {
        .select2-selection--single {
            background-color: transparent;
            border-color: $transparent-border  !important;
        }

        .select2-selection--single .select2-selection__placeholder {
            color: $white-5;
        }

        &.select2-container--focus .select2-selection--multiple {
            background-color: $transparent-theme;
            border-color: #9ca3b1;
            box-shadow: none;
        }

        .select2-selection--multiple {
            background-color: $transparent-theme;
            border-color: $transparent-border  !important;
        }

        .select2-search--dropdown .select2-search__field {
            border-color: $transparent-border  !important;
            background: $transparent-theme;
        }

        .select2-selection--multiple {

            .select2-selection__choice,
            .select2-selection__choice__remove {
                color: $white  !important;
            }
        }

        .select2-results>.select2-results__options {
            box-shadow: 0px 16px 18px rgba(104, 113, 123, 0.2);
        }

        .select2-selection--single .select2-selection__rendered {
            color: $white;
        }
    }

    .select2-container--default.select2-container--disabled .select2-selection--single {
        background: $transparent-theme;
    }

    .selectgroup-button {
        border-color: $transparent-border;
        color: $white-7;
    }

    .selectgroup-input {
        &:checked+.selectgroup-button {
            background: $transparent-theme;
        }

        &:focus+.selectgroup-button {
            box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
        }
    }

    /*-----selectize ------*/
    .selectize-dropdown {
        color: #495057;
    }

    .selectize-input {
        color: #495057;
        background: $transparent-theme;

        input {
            color: #495057;
        }
    }

    .selectize-input {
        border-color: $transparent-border;

        &.full {
            background-color: $transparent-theme;
        }

        &.focus {
            border-color: #467fcf;
            box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
        }
    }

    .selectize-input {
        &.dropdown-active::before {
            background: #f0f0f0;
        }
    }

    .selectize-dropdown {
        border-color: $transparent-border;
        background: $transparent-theme;

        [data-selectable] .highlight {
            background: rgba(125, 168, 208, 0.2);
        }

        .optgroup-header {
            color: #495057;
            background: $transparent-theme;
        }

        .active {
            background-color: #f1f4f8;
            color: #467fcf;

            &.create {
                color: #495057;
            }
        }

        .create {
            color: rgba(48, 48, 48, 0.5);
        }
    }

    .selectize-dropdown .image img,
    .selectize-input .image img {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    /* ######## SIDEMENU-STYLES ######## */
    @media (min-width: 992px) {
        .side-header {
            background: $white-05;
        }
    }

    @media (max-width: 991px) {
        &.app.sidebar-mini .app-sidebar {
            background: $primary-1;
        }

        @supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
            &.app.sidebar-mini .app-sidebar {
                background: transparent !important;
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
            }
        }
    }

    @media print {
        .app-content {
            background-color: $transparent-theme;
        }
    }

    .app-header {
        border-bottom-color: $transparent-border;
        background: $transparent-theme;
    }

    .app-header__logo {
        color: $white;
    }

    .ps__thumb-y {
        background-color: transparent;
        width: 1px;
    }

    .app-sidebar__toggle {
        color: $white;

        &:after {
            background: rgba($primary-1, 0);
        }
    }

    .app-sidebar {
        color: $white;
        background: $transparent-theme;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(42, 38, 53, 0);
        box-shadow: 0px 0px 0px 0px rgba(42, 38, 53, 0);
        border-right-color: $transparent-border;

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    .app-sidebar__user {
        color: #a8a8a8;

        img {
            box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
            border: rgba(255, 255, 255, 0.2);
            box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
        }
    }

    .app-sidebar__user-name,
    .app-sidebar__user-designation {
        color: #e5e9ec;
    }

    .side-menu__item {
        color: $white-8;

        &.active {
            color: $white  !important;

            &:hover,
            &:focus {
                color: $primary-1;
            }
        }

        &:hover,
        &:focus {
            color: $white;
        }

        &:hover {

            .side-menu__icon,
            .side-menu__label {
                color: $white  !important;
            }
        }

        &:focus {

            .side-menu__icon,
            .side-menu__label {
                color: $white;
            }
        }
    }

    .slide-item {

        &.active,
        &:hover,
        &:focus {
            color: #b5c1d2;
        }
    }

    .slide-menu a.active {
        color: $primary-1;
    }

    .slide-item,
    .sub-slide-item,
    .sub-slide-item2 {

        &.active,
        &:hover,
        &:focus {
            color: $white  !important;
        }
    }

    .sub-side-menu__item,
    .sub-side-menu__item2 {
        color: $white-8;
    }

    .sub-slide-item,
    .sub-slide-item2,
    .sub-side-menu__label,
    .sub-side-menu__label1,
    .sub-side-menu__label2 {

        &.active,
        &:hover,
        &:focus {
            color: $white;
        }
    }

    .slide-menu li .slide-item:before {
        color: $white;
    }

    .side-menu .side-menu__icon {
        color: $white-7  !important;
    }

    .slide-item {
        color: $white;
    }

    .side-menu__item.active .side-menu__icon {
        color: $white  !important;
    }

    @media (min-width: 992px) {
        .sidebar-mini.sidenav-toggled {
            .side-menu .side-menu__icon {
                background: none !important;
                box-shadow: none;
            }

            .sidebar-mini.sidenav-toggled.user-notification::before {
                background: transparent;
            }

            .app-sidebar__user {
                border-bottom-color: rgba(225, 225, 225, 0.05);
            }
        }

        &.horizontal {
            .sticky.stickyClass {
                .app-sidebar {
                    box-shadow: 0 8px 24px rgb(0 0 0 / 20%);
                    -webkit-box-shadow: 0 8px 24px rgb(0 0 0 / 20%);
                }
            }

            .horizontal-main .slide .slide-menu,
            .horizontal-main .slide .sub-slide-menu,
            .horizontal-main .slide .sub-slide-menu2 {
                background-color: $primary-1;
                border-color: $transparent-border;
                box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.3) !important;
            }
        }
    }

    .app-title {
        background-color: $transparent-theme;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    .app-breadcrumb {
        background-color: transparent;
    }

    .user-info {
        .text-dark {
            color: #25252a !important;
        }

        .text-muted {
            color: $white-7  !important;
        }
    }

    .side-header {
        border-bottom-color: $white-05;
        border-right-color: $white-05;
    }

    .side-menu {
        background: transparent;
    }

    &.horizontal {
        .side-menu {
            background: transparent;
        }

        .horizontal-main {
            background: $transparent-theme;
        }

        .main-sidemenu .slide-left,
        .main-sidemenu .slide-right {
            border-color: $transparent-border;

            svg {
                fill: $text-color;
            }
        }
    }

    .side-menu .sub-category {
        color: rgba(255, 255, 255, 0.3);
    }

    .side-menu .sub-side-menu__label,
    .sub-slide-label,
    .sub-side-menu__label2 {
        color: $white-8;
    }

    .sub-slide-item,
    .sub-slide-item2 {
        color: $text-color;
    }

    /*-- Subslide ---*/
    /*-- Subslide2 ---*/
    /* ######## TEMP-STYLES ######## */
    .richText {
        border: $transparent-border solid 1px;
        background-color: transparent !important;

        .richText-toolbar {
            border-bottom: rgba(156, 162, 161, 0) solid 1px;

            ul li a {
                border-right: $transparent-border solid 1px;
            }
        }
    }

    .transparent-mode .cal1 .clndr .clndr-table tr .day.event:hover,
    .cal1 .clndr .clndr-table tr .day.my-event:hover,
    .transparent-mode .cal1 .clndr .clndr-table tr .day.today,
    .cal1 .clndr .clndr-table tr .day.my-today {
        color: $white;
    }

    .cal1 .clndr {
        .clndr-table {
            .header-days .header-day {
                border-left-color: $transparent-border;
                border-top-color: $transparent-border;
                border-right-color: $transparent-border;
                color: $text-color;
            }

            tr .day.event:hover,
            .cal1 .clndr .clndr-table tr .day.my-event:hover {
                color: $white;
            }

            tr:last-child .day,
            .cal1 .clndr .clndr-table tr:last-child .my-day {
                border-bottom-color: $transparent-border;
            }

            tr {

                .empty,
                .adjacent-month,
                .my-empty,
                .my-adjacent-month {
                    border-left-color: $transparent-border;
                    border-top-color: $transparent-border;
                    color: $white-7;
                }

                .day {
                    border-left-color: $transparent-border;
                    border-top-color: $transparent-border;

                    &.event,
                    &.my-event {
                        background: $transparent-theme  !important;
                    }

                    &:last-child {
                        border-right-color: $transparent-border;
                    }

                    &:hover {
                        background: $transparent-body;
                    }
                }
            }
        }

        .clndr-controls {
            border-color: $transparent-border;
            background-color: transparent;
        }

        .clndr-controls .clndr-control-button {

            .clndr-previous-button,
            .clndr-next-button {
                color: $white;
            }
        }
    }

    .fc-unthemed {

        .fc-content,
        .fc-divider,
        .fc-list-heading td,
        .fc-list-view,
        .fc-popover,
        .fc-row,
        tbody,
        td,
        th,
        thead {
            border-color: $transparent-border;
        }
    }

    .fc-event,
    .fc-event-dot {
        color: $white-8  !important;
    }

    .fc-v-event .fc-event-main {
        color: $white-8  !important;
    }

    .fc-unthemed {

        .fc-divider,
        .fc-list-heading td,
        .fc-popover .fc-header {
            background: $transparent-border;
        }
    }

    .fc-toolbar {

        .fc-state-active,
        .ui-state-active {
            background: #b4b4b4;
        }
    }

    .fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
        border: none !important;
        box-shadow: none !important;
    }

    .fc-unthemed .fc-list-item:hover td {
        background-color: #eeeeee;
    }

    .cal1 .clndr .clndr-table tr {

        .empty:hover,
        .adjacent-month:hover,
        .my-empty:hover,
        .my-adjacent-month:hover {
            background: $transparent-theme;
        }
    }

    .cal1 .clndr .clndr-table .header-days .header-day {
        color: $white;
    }

    /*------ Charts styles ------*/
    .instagram {
        background: linear-gradient(to right bottom, #de497b 0%, #e1164f 100%);
    }

    .linkedin {
        background-image: linear-gradient(to right bottom, #0d97de 0%, #13547a 100%);
    }

    .twitter {
        background-image: linear-gradient(to right bottom, #00f2fe 0%, #1e63c3 100%);
    }

    .facebook {
        background-image: linear-gradient(to right bottom, #3d6cbf 0%, #1e3c72 100%);
    }

    .map-header:before {
        background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, $transparent-theme 95%);
    }

    /*----chart-drop-shadow----*/
    .chart-dropshadow {
        -webkit-filter: drop-shadow(-6px 5px 4px #2a2635);
        filter: drop-shadow(-6px 5px 4px #2a2635);
    }

    .chart-dropshadow-primary {
        -webkit-filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
        filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
    }

    .chart-dropshadow-primary-1 {
        -webkit-filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
        filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
    }

    .chart-dropshadow-danger {
        -webkit-filter: drop-shadow((-6px) 12px 4px rgba(244, 88, 91, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(244, 88, 91, 0.1));
    }

    .chart-dropshadow-warning {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(247, 183, 49, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(247, 183, 49, 0.1));
    }

    .BarChartShadow {
        -webkit-filter: drop-shadow((-4px) 9px 4px rgba(0, 0, 0, 0.3));
        filter: drop-shadow((-6px) 9px 4px rgba(0, 0, 0, 0.3));
    }

    /*----chart-drop-shadow----*/
    .chart-dropshadow2 {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(0, 0, 0, 0.2));
        filter: drop-shadow((-6px) 5px 4px rgba(0, 0, 0, 0.2));
    }

    .chart-dropshadow-secondary {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(130, 207, 242, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(130, 207, 242, 0.1));
    }

    .chart-dropshadow-success {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(19, 191, 166, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(19, 191, 166, 0.1));
    }

    .chart-dropshadow-info {
        -webkit-filter: drop-shadow((-6px) 5px 4px rgba(7, 116, 248, 0.1));
        filter: drop-shadow((-6px) 5px 4px rgba(7, 116, 248, 0.1));
    }

    .donutShadow {
        -webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
        filter: drop-shadow((-1px) 0px 2px rgba(159, 120, 255, 0.5));
    }

    .donutShadow-yellow {
        -webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
        filter: drop-shadow((-1px) 0px 2px rgba(251, 196, 52, 0.5));
    }

    .donutShadow-blue {
        -webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
        filter: drop-shadow((-1px) 0px 2px rgba(36, 72, 135, 0.5));
    }

    /* ###### Chat  ###### */
    .main-chat-contacts-wrapper {
        border-bottom-color: $transparent-border;
    }

    .main-chat-list {
        .media {
            border-color: $transparent-border;

            +.media {
                border-top-color: $transparent-border;
            }

            &.new {
                background-color: transparent;

                .media-contact-name span:first-child {
                    color: $white;
                }

                .media-body p {
                    color: rgba(255, 255, 255, 0.5);
                }
            }

            &:hover,
            &:focus {
                background-color: $transparent-theme;
                border-top-color: $transparent-border;
                border-bottom-color: $transparent-border;
            }

            &:hover:first-child,
            &:focus:first-child {
                border-top-color: transparent;
            }

            &.selected {
                background-color: $transparent-theme;
                border-top-color: $transparent-border;
                border-bottom-color: $transparent-border;

                &:first-child {
                    border-top-color: transparent;
                }

                .media-contact-name span:first-child {
                    color: $white;
                }

                .media-body p {
                    color: $white-7;
                }
            }
        }

        .main-img-user span {
            color: $white;
            background-color: $secondary;
            box-shadow: 0 0 0 2px $transparent-theme;
        }

        .media-body p {
            color: $white-7;
        }

        .media-contact-name span {
            &:first-child {
                color: $white;
            }

            &:last-child {
                color: $white-7;
            }
        }
    }

    .main-chat-header {
        border-bottom-color: $transparent-border;

        .nav-link {
            color: $white-7;
        }
    }

    .main-chat-msg-name small,
    .main-chat-body .media-body>div:last-child {
        color: $white-7;
    }

    .main-chat-time {
        span {
            background: transparent;
        }

        &::before,
        &::after {
            background-color: $transparent-border;
        }
    }

    .main-chat-footer {
        border-top-color: $transparent-border;
        background-color: $transparent-theme;

        .nav-link {
            color: $white-7;
        }

        .form-control {
            border-color: $transparent-border;

            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
    }

    .main-content-title {
        color: #170c6b;
    }

    .main-msg-wrapper {
        background-color: $transparent-body;
    }

    .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
        background-color: $transparent-theme;
        color: $white;
    }

    /* ###### Chat  ###### */
    .chat-profile {
        color: $white-7;
    }

    .shared-files {
        border-color: $transparent-border;
    }

    .main-chat-list .media {

        &:hover,
        &:focus {
            background: $transparent-theme  !important;
        }
    }

    .authentication {
        .card:hover {
            box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
        }

        .form-control:focus {
            box-shadow: none;
        }

        input::placeholder {
            color: $white-7;
        }
    }

    .wrap-login100 {
        background: $transparent-theme;
        box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
    }

    .login100-form-title {
        color: $white;
    }

    .input100 {
        color: #dedefd;
        background: transparent;
        border-color: $transparent-border;
    }

    .symbol-input100,
    .wrap-input100 input::-webkit-input-placeholder {
        color: $white-7;
    }

    .construction .btn.btn-icon {
        background: rgba(255, 255, 255, 0.08);
        color: $white;
    }

    /*----- Range slider -------*/
    .range {
        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

        input[type="range"] {
            background-color: transparent;

            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }

        output {
            color: white;
            background-color: #999999;
        }

        &.range-success {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }

            output {
                background-color: $green;
            }

            input[type="range"] {
                outline-color: $green;
            }
        }

        &.range-info {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }

            output {
                background-color: $azure;
            }

            input[type="range"] {
                outline-color: $azure;
            }
        }

        &.range-warning {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }

            output {
                background-color: #ecb403;
            }

            input[type="range"] {
                outline-color: #ecb403;
            }
        }

        &.range-danger {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #fb7d92;
                }
            }

            output {
                background-color: #fb7d92;
            }

            input[type="range"] {
                outline-color: #fb7d92;
            }
        }

        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

        input[type="range"] {
            background-color: transparent;

            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }

        output {
            color: white;
            background-color: #999999;
        }

        &.range-success {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }

            output {
                background-color: $green;
            }

            input[type="range"] {
                outline-color: $green;
            }
        }

        &.range-info {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }

            output {
                background-color: $azure;
            }

            input[type="range"] {
                outline-color: $azure;
            }
        }

        &.range-warning {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }

            output {
                background-color: #ecb403;
            }

            input[type="range"] {
                outline-color: #ecb403;
            }
        }

        &.range-danger {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #fb7d92;
                }
            }

            output {
                background-color: #fb7d92;
            }

            input[type="range"] {
                outline-color: #fb7d92;
            }
        }

        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

        input[type="range"] {
            background-color: transparent;

            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }

        output {
            color: white;
            background-color: #999999;
        }

        &.range-success {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }

            output {
                background-color: $green;
            }

            input[type="range"] {
                outline-color: $green;
            }
        }

        &.range-info {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }

            output {
                background-color: $azure;
            }

            input[type="range"] {
                outline-color: $azure;
            }
        }

        &.range-warning {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }

            output {
                background-color: #ecb403;
            }

            input[type="range"] {
                outline-color: #ecb403;
            }
        }

        &.range-danger {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #fb7d92;
                }
            }

            output {
                background-color: #fb7d92;
            }

            input[type="range"] {
                outline-color: #fb7d92;
            }
        }

        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

        input[type="range"] {
            background-color: transparent;

            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }

        output {
            color: white;
            background-color: #999999;
        }

        &.range-success {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }

            output {
                background-color: $green;
            }

            input[type="range"] {
                outline-color: $green;
            }
        }

        &.range-info {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }

            output {
                background-color: $azure;
            }

            input[type="range"] {
                outline-color: $azure;
            }
        }

        &.range-warning {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }

            output {
                background-color: #ecb403;
            }

            input[type="range"] {
                outline-color: #ecb403;
            }
        }

        &.range-danger {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #fb7d92;
                }
            }

            output {
                background-color: #fb7d92;
            }

            input[type="range"] {
                outline-color: #fb7d92;
            }
        }

        background-color: whitesmoke;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

        input[type="range"] {
            background-color: transparent;

            &::-webkit-slider-thumb,
            &::-moz-slider-thumb {
                color: white;
                background-color: #999999;
            }
        }

        output {
            color: white;
            background-color: #999999;
        }

        &.range-success {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $green;
                }
            }

            output {
                background-color: $green;
            }

            input[type="range"] {
                outline-color: $green;
            }
        }

        &.range-info {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: $azure;
                }
            }

            output {
                background-color: $azure;
            }

            input[type="range"] {
                outline-color: $azure;
            }
        }

        &.range-warning {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #ecb403;
                }
            }

            output {
                background-color: #ecb403;
            }

            input[type="range"] {
                outline-color: #ecb403;
            }
        }

        &.range-danger {
            input[type="range"] {

                &::-webkit-slider-thumb,
                &::-moz-slider-thumb {
                    background-color: #fb7d92;
                }
            }

            output {
                background-color: #fb7d92;
            }

            input[type="range"] {
                outline-color: #fb7d92;
            }
        }
    }

    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    .ribbon1 {
        color: $white;

        &:after {
            border-left: 20px solid transparent;
            border-right: 24px solid transparent;
            border-top: 13px solid #f8463f;
        }

        span {
            background: #f8463f;

            &:before {
                background: #f8463f;
            }

            &:after {
                background: #c02031;
            }
        }
    }

    .ribbon span {
        color: $white;
        background: #79a70a;
        background: linear-gradient(#f8463f 0%, #f8463f 100%);
        box-shadow: 0 3px 10px -5px black;

        &::before {
            border-left: 3px solid #f8463f;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #f8463f;
        }

        &::after {
            border-left: 3px solid transparent;
            border-right: 3px solid #f8463f;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #f8463f;
        }
    }

    /*--- WIZARD ELEMENTS ---*/
    .wizard {
        border-color: $transparent-border;
        background-color: $transparent-theme;

        > {
            .steps {
                a {
                    color: $primary-1;

                    &:hover,
                    &:active {
                        color: $primary-1;
                    }

                    .number,
                    &:hover .number,
                    &:active .number {
                        background-color: #ededf3;
                    }
                }

                .disabled a {
                    color: $white-7;

                    &:hover,
                    &:active {
                        color: $white-7;
                    }
                }

                .current a {
                    color: $primary-1;

                    &:hover,
                    &:active {
                        color: $primary-1;
                    }

                    .number,
                    &:hover .number,
                    &:active .number {
                        background-color: $white-1;
                        color: $white;
                    }
                }

                .done a {
                    color: $success;

                    &:hover,
                    &:active {
                        color: $success;
                    }

                    .number,
                    &:hover .number,
                    &:active .number {
                        background-color: $success;
                        color: $white;
                    }
                }
            }

            .content {
                border-top-color: $transparent-border;
                border-bottom-color: $transparent-border;

                > {
                    .title {
                        color: $default-color;
                    }

                    .body input.parsley-error {
                        border-color: #ff5c77;
                    }
                }
            }

            .actions {
                >ul>li:last-child a {
                    background-color: $success;
                }

                a {
                    background-color: $primary-1;
                    color: $white;

                    &:hover,
                    &:active {
                        background-color: $primary-1;
                        color: $white;
                    }
                }

                .disabled a {
                    background-color: $transparent-body;
                    color: $white-7;

                    &:hover,
                    &:active {
                        background-color: $transparent-body;
                        color: $white-7;
                    }
                }
            }
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical>.content {
            border-left-color: $transparent-border;
            border-right-color: $transparent-border;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical>.actions {
            border-left-color: $transparent-border;
            border-right-color: $transparent-border;
        }
    }

    /****** EQUAL COLUMN WIDTH STEP INDICATOR *****/
    /***** CUSTOM STYLES *****/
    .wizard-style-1>.steps>ul {
        a {

            .number,
            &:hover .number,
            &:active .number {
                color: #696e8d;
                background-color: #f3f7fd;
            }
        }

        .current a {

            .number,
            &:hover .number,
            &:active .number {
                background-color: $primary-1;
                color: $white;
            }
        }

        .done a {

            .number,
            &:hover .number,
            &:active .number {
                background-color: #643ab0;
                color: $white;
            }
        }
    }

    .wizard-style-2>.steps>ul {
        a {

            .number,
            &:hover .number,
            &:active .number {
                border: 2px solid #f3f7fd;
                color: #696e8d;
                background-color: $transparent-theme;
            }
        }

        .current a {

            .number,
            &:hover .number,
            &:active .number {
                border-color: $primary-1;
                color: $primary-1;
            }
        }

        .done a {

            .number,
            &:hover .number,
            &:active .number {
                border-color: #8c3feb;
                color: #8c3feb;
            }
        }
    }

    /*--- WIZARD ELEMENTS ---*/
    .parsley-required {
        color: #ff5c77;
    }

    .wizard-card .moving-tab {
        background-color: $primary-1  !important;
    }

    .form-group label.control-label {
        color: $primary-1;
    }

    .wizard-card.form-group .form-control {
        background-image: linear-gradient(#c4c4c4, #c4c4c4, linear-gradient(#e1e1e2, #e1e1e2));
    }

    .wizard-container .wizard-navigation {
        background: #ebeff8;
    }

    .wizard-card .choice {

        &:hover .icon,
        &.active .icon {
            border-color: $primary-1  !important;
            color: $primary-1  !important;
        }
    }

    .widgets-cards .wrp.icon-circle i {
        color: $white;
    }

    /* ######## UTILITIES-STYLES ######## */
    .bg-secondary {
        background: $secondary  !important;
    }

    a.bg-secondary {

        &:hover,
        &:focus {
            background-color: $secondary  !important;
        }
    }

    button.bg-secondary {

        &:hover,
        &:focus {
            background-color: $secondary  !important;
        }
    }

    .bg-success {
        background: $success  !important;
    }

    a.bg-success {

        &:hover,
        &:focus {
            background-color: #15bf42 !important;
        }
    }

    button.bg-success {

        &:hover,
        &:focus {
            background-color: #15bf42 !important;
        }
    }

    .bg-info {
        background: $info  !important;
    }

    a.bg-info {

        &:hover,
        &:focus {
            background-color: #1eb0e2 !important;
        }
    }

    button.bg-info {

        &:hover,
        &:focus {
            background-color: #1eb0e2 !important;
        }
    }

    .bg-warning {
        background: $warning  !important;
    }

    a.bg-warning {

        &:hover,
        &:focus {
            background-color: #e0a325 !important;
        }
    }

    button.bg-warning {

        &:hover,
        &:focus {
            background-color: #e0a325 !important;
        }
    }

    .bg-danger {
        background: $danger  !important;
    }

    a.bg-danger {

        &:hover,
        &:focus {
            background-color: #de223d !important;
        }
    }

    button.bg-danger {

        &:hover,
        &:focus {
            background-color: #de223d !important;
        }
    }

    .bg-light {
        background-color: $transparent-body  !important;
    }

    a.bg-light {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    button.bg-light {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    .bg-dark {
        background-color: $dark  !important;
    }

    a.bg-dark {

        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }

    button.bg-dark {

        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }

    .bg-facebook {
        background: #2b4170 !important;
    }

    /*--- gradient-backgrounds --*/
    .bg-secondary-gradient {
        background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%) !important;
    }

    a.bg-secondary-gradient {

        &:hover,
        &:focus {
            background-color: $secondary  !important;
        }
    }

    button.bg-secondary-gradient {

        &:hover,
        &:focus {
            background-color: $secondary  !important;
        }
    }

    .bg-success-gradient {
        background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%) !important;
    }

    a.bg-success-gradient {

        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }

    button.bg-success-gradient {

        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }

    .bg-info-gradient {
        background: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%) !important;
    }

    a.bg-info-gradient {

        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }

    button.bg-info-gradient {

        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }

    .bg-warning-gradient {
        background: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%) !important;
    }

    a.bg-warning-gradient {

        &:hover,
        &:focus {
            background-color: $yellow  !important;
        }
    }

    button.bg-warning-gradient {

        &:hover,
        &:focus {
            background-color: $yellow  !important;
        }
    }

    .bg-danger-gradient {
        background-image: linear-gradient(to bottom right, #b51b35 0%, #fd4a68 100%) !important;
    }

    a.bg-danger-gradient {

        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }

    button.bg-danger-gradient {

        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }

    .bg-light-gradient {
        background-color: #f8f9fa !important;
    }

    a.bg-light-gradient {

        &:hover,
        &:focus {
            background-color: #dae0e5 !important;
        }
    }

    button.bg-light-gradient {

        &:hover,
        &:focus {
            background-color: #dae0e5 !important;
        }
    }

    .bg-dark-gradient {
        background-color: $dark  !important;
    }

    a.bg-dark-gradient {

        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }

    button.bg-dark-gradient {

        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }

    .bg-facebook-gradient {
        background: linear-gradient(to bottom right, #3b5998, #2b4170) !important;
    }

    .bg-white {
        background-color: $transparent-theme  !important;
    }

    .bg-transparent {
        background-color: transparent !important;
    }

    .bg1 {
        background: linear-gradient(to right bottom, #163b7c 0%, #548beb 100%);
    }

    .bg2 {
        background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
    }

    .bg3 {
        background: linear-gradient(to bottom right, #f53e31, #dd4b39);
    }

    /*------ Background colors -------*/
    .bg-purple {
        background: $purple  !important;
        color: $white  !important;
    }

    a.bg-purple {

        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }

    button.bg-purple {

        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }

    .bg-blue-lightest {
        background-color: #edf2fa !important;
    }

    a.bg-blue-lightest {

        &:hover,
        &:focus {
            background-color: #c5d5ef !important;
        }
    }

    button.bg-blue-lightest {

        &:hover,
        &:focus {
            background-color: #c5d5ef !important;
        }
    }

    .bg-blue-lighter {
        background-color: #c8d9f1 !important;
    }

    a.bg-blue-lighter {

        &:hover,
        &:focus {
            background-color: #9fbde7 !important;
        }
    }

    button.bg-blue-lighter {

        &:hover,
        &:focus {
            background-color: #9fbde7 !important;
        }
    }

    .bg-blue-light {
        background-color: #7ea5dd !important;
    }

    a.bg-blue-light {

        &:hover,
        &:focus {
            background-color: #5689d2 !important;
        }
    }

    button.bg-blue-light {

        &:hover,
        &:focus {
            background-color: #5689d2 !important;
        }
    }

    .bg-blue-dark {
        background-color: #3866a6 !important;
    }

    a.bg-blue-dark {

        &:hover,
        &:focus {
            background-color: #2b4f80 !important;
        }
    }

    button.bg-blue-dark {

        &:hover,
        &:focus {
            background-color: #2b4f80 !important;
        }
    }

    .bg-blue-darker {
        background-color: #1c3353 !important;
    }

    a.bg-blue-darker {

        &:hover,
        &:focus {
            background-color: #0f1c2d !important;
        }
    }

    button.bg-blue-darker {

        &:hover,
        &:focus {
            background-color: #0f1c2d !important;
        }
    }

    .bg-blue-darkest {
        background-color: #0e1929 !important;
    }

    a.bg-blue-darkest {

        &:hover,
        &:focus {
            background-color: #010203 !important;
        }
    }

    button.bg-blue-darkest {

        &:hover,
        &:focus {
            background-color: #010203 !important;
        }
    }

    .bg-purssianblue {
        background-color: #362f71;
    }

    a.bg-purssianblue-lightest {

        &:hover,
        &:focus {
            background-color: #3f3688 !important;
        }
    }

    button.bg-purssianblue-lightest {

        &:hover,
        &:focus {
            background-color: #3f3688 !important;
        }
    }

    .bg-indigo-lightest {
        background-color: #f0f1fa !important;
    }

    a.bg-indigo-lightest {

        &:hover,
        &:focus {
            background-color: #cacded !important;
        }
    }

    button.bg-indigo-lightest {

        &:hover,
        &:focus {
            background-color: #cacded !important;
        }
    }

    .bg-indigo-lighter {
        background-color: #d1d5f0 !important;
    }

    a.bg-indigo-lighter {

        &:hover,
        &:focus {
            background-color: #abb2e3 !important;
        }
    }

    button.bg-indigo-lighter {

        &:hover,
        &:focus {
            background-color: #abb2e3 !important;
        }
    }

    .bg-indigo-light {
        background-color: #939edc !important;
    }

    a.bg-indigo-light {

        &:hover,
        &:focus {
            background-color: #6c7bd0 !important;
        }
    }

    button.bg-indigo-light {

        &:hover,
        &:focus {
            background-color: #6c7bd0 !important;
        }
    }

    .bg-indigo-dark {
        background-color: #515da4 !important;
    }

    a.bg-indigo-dark {

        &:hover,
        &:focus {
            background-color: #404a82 !important;
        }
    }

    button.bg-indigo-dark {

        &:hover,
        &:focus {
            background-color: #404a82 !important;
        }
    }

    .bg-indigo-darker {
        background-color: #282e52 !important;
    }

    a.bg-indigo-darker {

        &:hover,
        &:focus {
            background-color: #171b30 !important;
        }
    }

    button.bg-indigo-darker {

        &:hover,
        &:focus {
            background-color: #171b30 !important;
        }
    }

    .bg-indigo-darkest {
        background-color: #141729 !important;
    }

    a.bg-indigo-darkest {

        &:hover,
        &:focus {
            background-color: #030407 !important;
        }
    }

    button.bg-indigo-darkest {

        &:hover,
        &:focus {
            background-color: #030407 !important;
        }
    }

    .bg-purple-lightest {
        background-color: #f6effd !important;
    }

    a.bg-purple-lightest {

        &:hover,
        &:focus {
            background-color: #ddc2f7 !important;
        }
    }

    button.bg-purple-lightest {

        &:hover,
        &:focus {
            background-color: #ddc2f7 !important;
        }
    }

    .bg-purple-lighter {
        background-color: #e4cff9 !important;
    }

    a.bg-purple-lighter {

        &:hover,
        &:focus {
            background-color: #cba2f3 !important;
        }
    }

    button.bg-purple-lighter {

        &:hover,
        &:focus {
            background-color: #cba2f3 !important;
        }
    }

    .bg-purple-light {
        background-color: #c08ef0 !important;
    }

    a.bg-purple-light {

        &:hover,
        &:focus {
            background-color: #a761ea !important;
        }
    }

    button.bg-purple-light {

        &:hover,
        &:focus {
            background-color: #a761ea !important;
        }
    }

    .bg-purple-dark {
        background-color: #844bbb !important;
    }

    a.bg-purple-dark {

        &:hover,
        &:focus {
            background-color: #6a3a99 !important;
        }
    }

    button.bg-purple-dark {

        &:hover,
        &:focus {
            background-color: #6a3a99 !important;
        }
    }

    .bg-purple-darker {
        background-color: #42265e !important;
    }

    a.bg-purple-darker {

        &:hover,
        &:focus {
            background-color: #29173a !important;
        }
    }

    button.bg-purple-darker {

        &:hover,
        &:focus {
            background-color: #29173a !important;
        }
    }

    .bg-purple-darkest {
        background-color: #21132f !important;
    }

    a.bg-purple-darkest {

        &:hover,
        &:focus {
            background-color: #08040b !important;
        }
    }

    button.bg-purple-darkest {

        &:hover,
        &:focus {
            background-color: #08040b !important;
        }
    }

    .bg-pink-lightest {
        background-color: #fef0f5 !important;
    }

    a.bg-pink-lightest {

        &:hover,
        &:focus {
            background-color: #fbc0d5 !important;
        }
    }

    button.bg-pink-lightest {

        &:hover,
        &:focus {
            background-color: #fbc0d5 !important;
        }
    }

    .bg-pink-lighter {
        background-color: #fcd3e1 !important;
    }

    a.bg-pink-lighter {

        &:hover,
        &:focus {
            background-color: #f9a3c0 !important;
        }
    }

    button.bg-pink-lighter {

        &:hover,
        &:focus {
            background-color: #f9a3c0 !important;
        }
    }

    .bg-pink-light {
        background-color: #f999b9 !important;
    }

    a.bg-pink-light {

        &:hover,
        &:focus {
            background-color: #f66998 !important;
        }
    }

    button.bg-pink-light {

        &:hover,
        &:focus {
            background-color: #f66998 !important;
        }
    }

    .bg-pink-dark {
        background-color: #c5577c !important;
    }

    a.bg-pink-dark {

        &:hover,
        &:focus {
            background-color: #ad3c62 !important;
        }
    }

    button.bg-pink-dark {

        &:hover,
        &:focus {
            background-color: #ad3c62 !important;
        }
    }

    .bg-pink-darker {
        background-color: #622c3e !important;
    }

    a.bg-pink-darker {

        &:hover,
        &:focus {
            background-color: #3f1c28 !important;
        }
    }

    button.bg-pink-darker {

        &:hover,
        &:focus {
            background-color: #3f1c28 !important;
        }
    }

    .bg-pink-darkest {
        background-color: #31161f !important;
    }

    a.bg-pink-darkest {

        &:hover,
        &:focus {
            background-color: #0e0609 !important;
        }
    }

    button.bg-pink-darkest {

        &:hover,
        &:focus {
            background-color: #0e0609 !important;
        }
    }

    .bg-red-lightest {
        background-color: #fae9e9 !important;
    }

    a.bg-red-lightest {

        &:hover,
        &:focus {
            background-color: #f1bfbf !important;
        }
    }

    button.bg-red-lightest {

        &:hover,
        &:focus {
            background-color: #f1bfbf !important;
        }
    }

    .bg-red-lighter {
        background-color: #f0bcbc !important;
    }

    a.bg-red-lighter {

        &:hover,
        &:focus {
            background-color: #e79292 !important;
        }
    }

    button.bg-red-lighter {

        &:hover,
        &:focus {
            background-color: #e79292 !important;
        }
    }

    .bg-red-light {
        background-color: #dc6362 !important;
    }

    a.bg-red-light {

        &:hover,
        &:focus {
            background-color: #d33a38 !important;
        }
    }

    button.bg-red-light {

        &:hover,
        &:focus {
            background-color: #d33a38 !important;
        }
    }

    .bg-red-dark {
        background-color: #a41a19 !important;
    }

    a.bg-red-dark {

        &:hover,
        &:focus {
            background-color: #781312 !important;
        }
    }

    button.bg-red-dark {

        &:hover,
        &:focus {
            background-color: #781312 !important;
        }
    }

    .bg-red-darker {
        background-color: #520d0c !important;
    }

    a.bg-red-darker {

        &:hover,
        &:focus {
            background-color: #260605 !important;
        }
    }

    button.bg-red-darker {

        &:hover,
        &:focus {
            background-color: #260605 !important;
        }
    }

    .bg-red-darkest {
        background-color: #290606 !important;
    }

    a.bg-red-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-red-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-orange-lightest {
        background-color: $transparent-theme  !important;
    }

    a.bg-orange-lightest {

        &:hover,
        &:focus {
            background-color: peachpuff !important;
        }
    }

    button.bg-orange-lightest {

        &:hover,
        &:focus {
            background-color: peachpuff !important;
        }
    }

    .bg-orange-lighter {
        background-color: #fee0c7 !important;
    }

    a.bg-orange-lighter {

        &:hover,
        &:focus {
            background-color: #fdc495 !important;
        }
    }

    button.bg-orange-lighter {

        &:hover,
        &:focus {
            background-color: #fdc495 !important;
        }
    }

    .bg-orange-light {
        background-color: #feb67c !important;
    }

    a.bg-orange-light {

        &:hover,
        &:focus {
            background-color: #fe9a49 !important;
        }
    }

    button.bg-orange-light {

        &:hover,
        &:focus {
            background-color: #fe9a49 !important;
        }
    }

    .bg-orange-dark {
        background-color: #ca7836 !important;
    }

    a.bg-orange-dark {

        &:hover,
        &:focus {
            background-color: #a2602b !important;
        }
    }

    button.bg-orange-dark {

        &:hover,
        &:focus {
            background-color: #a2602b !important;
        }
    }

    .bg-orange-darker {
        background-color: #653c1b !important;
    }

    a.bg-orange-darker {

        &:hover,
        &:focus {
            background-color: #3d2410 !important;
        }
    }

    button.bg-orange-darker {

        &:hover,
        &:focus {
            background-color: #3d2410 !important;
        }
    }

    .bg-orange-darkest {
        background-color: #331e0e !important;
    }

    a.bg-orange-darkest {

        &:hover,
        &:focus {
            background-color: #0b0603 !important;
        }
    }

    button.bg-orange-darkest {

        &:hover,
        &:focus {
            background-color: #0b0603 !important;
        }
    }

    .bg-yellow-lightest {
        background-color: #fef9e7 !important;
    }

    a.bg-yellow-lightest {

        &:hover,
        &:focus {
            background-color: #fcedb6 !important;
        }
    }

    button.bg-yellow-lightest {

        &:hover,
        &:focus {
            background-color: #fcedb6 !important;
        }
    }

    .bg-yellow-lighter {
        background-color: #fbedb7 !important;
    }

    a.bg-yellow-lighter {

        &:hover,
        &:focus {
            background-color: #f8e187 !important;
        }
    }

    button.bg-yellow-lighter {

        &:hover,
        &:focus {
            background-color: #f8e187 !important;
        }
    }

    .bg-yellow-light {
        background-color: #f5d657 !important;
    }

    a.bg-yellow-light {

        &:hover,
        &:focus {
            background-color: #f2ca27 !important;
        }
    }

    button.bg-yellow-light {

        &:hover,
        &:focus {
            background-color: #f2ca27 !important;
        }
    }

    .bg-yellow-dark {
        background-color: #c19d0c !important;
    }

    a.bg-yellow-dark {

        &:hover,
        &:focus {
            background-color: #917609 !important;
        }
    }

    button.bg-yellow-dark {

        &:hover,
        &:focus {
            background-color: #917609 !important;
        }
    }

    .bg-yellow-darker {
        background-color: #604e06 !important;
    }

    a.bg-yellow-darker {

        &:hover,
        &:focus {
            background-color: #302703 !important;
        }
    }

    button.bg-yellow-darker {

        &:hover,
        &:focus {
            background-color: #302703 !important;
        }
    }

    .bg-yellow-darkest {
        background-color: #302703 !important;
    }

    a.bg-yellow-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-yellow-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-green-lightest {
        background-color: #eff8e6 !important;
    }

    a.bg-green-lightest {

        &:hover,
        &:focus {
            background-color: #d6edbe !important;
        }
    }

    button.bg-green-lightest {

        &:hover,
        &:focus {
            background-color: #d6edbe !important;
        }
    }

    .bg-green-lighter {
        background-color: #cfeab3 !important;
    }

    a.bg-green-lighter {

        &:hover,
        &:focus {
            background-color: #b6df8b !important;
        }
    }

    button.bg-green-lighter {

        &:hover,
        &:focus {
            background-color: #b6df8b !important;
        }
    }

    .bg-green-light {
        background-color: #8ecf4d !important;
    }

    a.bg-green-light {

        &:hover,
        &:focus {
            background-color: #75b831 !important;
        }
    }

    button.bg-green-light {

        &:hover,
        &:focus {
            background-color: #75b831 !important;
        }
    }

    .bg-green-dark {
        background-color: #4b9500 !important;
    }

    a.bg-green-dark {

        &:hover,
        &:focus {
            background-color: #316200 !important;
        }
    }

    button.bg-green-dark {

        &:hover,
        &:focus {
            background-color: #316200 !important;
        }
    }

    .bg-green-darker {
        background-color: #264a00 !important;
    }

    a.bg-green-darker {

        &:hover,
        &:focus {
            background-color: #0c1700 !important;
        }
    }

    button.bg-green-darker {

        &:hover,
        &:focus {
            background-color: #0c1700 !important;
        }
    }

    .bg-green-darkest {
        background-color: #132500 !important;
    }

    a.bg-green-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-green-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-teal-lightest {
        background-color: #eafaf8 !important;
    }

    a.bg-teal-lightest {

        &:hover,
        &:focus {
            background-color: #c1f0ea !important;
        }
    }

    button.bg-teal-lightest {

        &:hover,
        &:focus {
            background-color: #c1f0ea !important;
        }
    }

    .bg-teal-lighter {
        background-color: #bfefea !important;
    }

    a.bg-teal-lighter {

        &:hover,
        &:focus {
            background-color: #96e5dd !important;
        }
    }

    button.bg-teal-lighter {

        &:hover,
        &:focus {
            background-color: #96e5dd !important;
        }
    }

    .bg-teal-light {
        background-color: #6bdbcf !important;
    }

    a.bg-teal-light {

        &:hover,
        &:focus {
            background-color: #42d1c2 !important;
        }
    }

    button.bg-teal-light {

        &:hover,
        &:focus {
            background-color: #42d1c2 !important;
        }
    }

    .bg-teal-dark {
        background-color: #22a295 !important;
    }

    a.bg-teal-dark {

        &:hover,
        &:focus {
            background-color: #19786e !important;
        }
    }

    button.bg-teal-dark {

        &:hover,
        &:focus {
            background-color: #19786e !important;
        }
    }

    .bg-teal-darker {
        background-color: #11514a !important;
    }

    a.bg-teal-darker {

        &:hover,
        &:focus {
            background-color: #082723 !important;
        }
    }

    button.bg-teal-darker {

        &:hover,
        &:focus {
            background-color: #082723 !important;
        }
    }

    .bg-teal-darkest {
        background-color: #092925 !important;
    }

    a.bg-teal-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-teal-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-cyan-lightest {
        background-color: #e8f6f8 !important;
    }

    a.bg-cyan-lightest {

        &:hover,
        &:focus {
            background-color: #c1e7ec !important;
        }
    }

    button.bg-cyan-lightest {

        &:hover,
        &:focus {
            background-color: #c1e7ec !important;
        }
    }

    .bg-cyan-lighter {
        background-color: #b9e3ea !important;
    }

    a.bg-cyan-lighter {

        &:hover,
        &:focus {
            background-color: #92d3de !important;
        }
    }

    button.bg-cyan-lighter {

        &:hover,
        &:focus {
            background-color: #92d3de !important;
        }
    }

    .bg-cyan-light {
        background-color: #5dbecd !important;
    }

    a.bg-cyan-light {

        &:hover,
        &:focus {
            background-color: #3aabbd !important;
        }
    }

    button.bg-cyan-light {

        &:hover,
        &:focus {
            background-color: #3aabbd !important;
        }
    }

    .bg-cyan-dark {
        background-color: #128293 !important;
    }

    a.bg-cyan-dark {

        &:hover,
        &:focus {
            background-color: #0c5a66 !important;
        }
    }

    button.bg-cyan-dark {

        &:hover,
        &:focus {
            background-color: #0c5a66 !important;
        }
    }

    .bg-cyan-darker {
        background-color: #09414a !important;
    }

    a.bg-cyan-darker {

        &:hover,
        &:focus {
            background-color: #03191d !important;
        }
    }

    button.bg-cyan-darker {

        &:hover,
        &:focus {
            background-color: #03191d !important;
        }
    }

    .bg-cyan-darkest {
        background-color: #052025 !important;
    }

    a.bg-cyan-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-cyan-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-white-lightest {
        background-color: white !important;
    }

    a.bg-white-lightest {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    button.bg-white-lightest {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    .bg-white-lighter {
        background-color: white !important;
    }

    a.bg-white-lighter {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    button.bg-white-lighter {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    .bg-white-light {
        background-color: white !important;
    }

    a.bg-white-light {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    button.bg-white-light {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    .bg-white-dark {
        background-color: #cccccc !important;
    }

    a.bg-white-dark {

        &:hover,
        &:focus {
            background-color: #b3b2b2 !important;
        }
    }

    button.bg-white-dark {

        &:hover,
        &:focus {
            background-color: #b3b2b2 !important;
        }
    }

    .bg-white-darker {
        background-color: #666666 !important;
    }

    a.bg-white-darker {

        &:hover,
        &:focus {
            background-color: #4d4c4c !important;
        }
    }

    button.bg-white-darker {

        &:hover,
        &:focus {
            background-color: #4d4c4c !important;
        }
    }

    .bg-white-darkest {
        background-color: #333333 !important;
    }

    a.bg-white-darkest {

        &:hover,
        &:focus {
            background-color: #1a1919 !important;
        }
    }

    button.bg-white-darkest {

        &:hover,
        &:focus {
            background-color: #1a1919 !important;
        }
    }

    .bg-gray-lightest {
        background-color: #f3f4f5 !important;
    }

    a.bg-gray-lightest {

        &:hover,
        &:focus {
            background-color: #d7dbde !important;
        }
    }

    button.bg-gray-lightest {

        &:hover,
        &:focus {
            background-color: #d7dbde !important;
        }
    }

    .bg-gray-lighter {
        background-color: #dbdde0 !important;
    }

    a.bg-gray-lighter {

        &:hover,
        &:focus {
            background-color: #c0c3c8 !important;
        }
    }

    button.bg-gray-lighter {

        &:hover,
        &:focus {
            background-color: #c0c3c8 !important;
        }
    }

    .bg-gray-light {
        background-color: #aab0b6 !important;
    }

    a.bg-gray-light {

        &:hover,
        &:focus {
            background-color: #8f979e !important;
        }
    }

    button.bg-gray-light {

        &:hover,
        &:focus {
            background-color: #8f979e !important;
        }
    }

    .bg-gray-dark {
        background-color: #6b7278 !important;
        background: $dark  !important;
    }

    a.bg-gray-dark {

        &:hover,
        &:focus {
            background-color: #53585d !important;
        }
    }

    button.bg-gray-dark {

        &:hover,
        &:focus {
            background-color: #53585d !important;
        }
    }

    .bg-gray-darker {
        background-color: #36393c !important;
    }

    a.bg-gray-darker {

        &:hover,
        &:focus {
            background-color: #1e2021 !important;
        }
    }

    button.bg-gray-darker {

        &:hover,
        &:focus {
            background-color: #1e2021 !important;
        }
    }

    .bg-gray-darkest {
        background-color: #1b1c1e !important;
    }

    a.bg-gray-darkest {

        &:hover,
        &:focus {
            background-color: #030303 !important;
        }
    }

    button.bg-gray-darkest {

        &:hover,
        &:focus {
            background-color: #030303 !important;
        }
    }

    .bg-gray-dark-lightest {
        background-color: #ebebec !important;
    }

    a.bg-gray-dark-lightest {

        &:hover,
        &:focus {
            background-color: #d1d1d3 !important;
        }
    }

    button.bg-gray-dark-lightest {

        &:hover,
        &:focus {
            background-color: #d1d1d3 !important;
        }
    }

    .bg-gray-dark-lighter {
        background-color: #c2c4c6 !important;
    }

    a.bg-gray-dark-lighter {

        &:hover,
        &:focus {
            background-color: #a8abad !important;
        }
    }

    button.bg-gray-dark-lighter {

        &:hover,
        &:focus {
            background-color: #a8abad !important;
        }
    }

    .bg-gray-dark-light {
        background-color: #717579 !important;
    }

    a.bg-gray-dark-light {

        &:hover,
        &:focus {
            background-color: #585c5f !important;
        }
    }

    button.bg-gray-dark-light {

        &:hover,
        &:focus {
            background-color: #585c5f !important;
        }
    }

    .bg-gray-dark-dark {
        background-color: #2a2e33 !important;
    }

    a.bg-gray-dark-dark {

        &:hover,
        &:focus {
            background-color: #131517 !important;
        }
    }

    button.bg-gray-dark-dark {

        &:hover,
        &:focus {
            background-color: #131517 !important;
        }
    }

    .bg-gray-dark-darker {
        background-color: #15171a !important;
    }

    a.bg-gray-dark-darker {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-gray-dark-darker {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-gray-dark-darkest {
        background-color: #0a0c0d !important;
    }

    a.bg-gray-dark-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    button.bg-gray-dark-darkest {

        &:hover,
        &:focus {
            background-color: black !important;
        }
    }

    .bg-azure-lightest {
        background-color: #ecf7fe !important;
    }

    a.bg-azure-lightest {

        &:hover,
        &:focus {
            background-color: #bce3fb !important;
        }
    }

    button.bg-azure-lightest {

        &:hover,
        &:focus {
            background-color: #bce3fb !important;
        }
    }

    .bg-azure-lighter {
        background-color: #c7e6fb !important;
    }

    a.bg-azure-lighter {

        &:hover,
        &:focus {
            background-color: #97d1f8 !important;
        }
    }

    button.bg-azure-lighter {

        &:hover,
        &:focus {
            background-color: #97d1f8 !important;
        }
    }

    .bg-azure-light {
        background-color: #7dc4f6 !important;
    }

    a.bg-azure-light {

        &:hover,
        &:focus {
            background-color: #4daef3 !important;
        }
    }

    button.bg-azure-light {

        &:hover,
        &:focus {
            background-color: #4daef3 !important;
        }
    }

    .bg-azure-dark {
        background-color: #3788c2 !important;
    }

    a.bg-azure-dark {

        &:hover,
        &:focus {
            background-color: #2c6c9a !important;
        }
    }

    button.bg-azure-dark {

        &:hover,
        &:focus {
            background-color: #2c6c9a !important;
        }
    }

    .bg-azure-darker {
        background-color: #1c4461 !important;
    }

    a.bg-azure-darker {

        &:hover,
        &:focus {
            background-color: #112839 !important;
        }
    }

    button.bg-azure-darker {

        &:hover,
        &:focus {
            background-color: #112839 !important;
        }
    }

    .bg-azure-darkest {
        background-color: #0e2230 !important;
    }

    a.bg-azure-darkest {

        &:hover,
        &:focus {
            background-color: #020609 !important;
        }
    }

    button.bg-azure-darkest {

        &:hover,
        &:focus {
            background-color: #020609 !important;
        }
    }

    .bg-lime-lightest {
        background-color: #f2fbeb !important;
    }

    a.bg-lime-lightest {

        &:hover,
        &:focus {
            background-color: #d6f3c1 !important;
        }
    }

    button.bg-lime-lightest {

        &:hover,
        &:focus {
            background-color: #d6f3c1 !important;
        }
    }

    .bg-lime-lighter {
        background-color: #d7f2c2 !important;
    }

    a.bg-lime-lighter {

        &:hover,
        &:focus {
            background-color: #bbe998 !important;
        }
    }

    button.bg-lime-lighter {

        &:hover,
        &:focus {
            background-color: #bbe998 !important;
        }
    }

    .bg-lime-light {
        background-color: #a3e072 !important;
    }

    a.bg-lime-light {

        &:hover,
        &:focus {
            background-color: #88d748 !important;
        }
    }

    button.bg-lime-light {

        &:hover,
        &:focus {
            background-color: #88d748 !important;
        }
    }

    .bg-lime-dark {
        background-color: #62a82a !important;
    }

    a.bg-lime-dark {

        &:hover,
        &:focus {
            background-color: #4a7f20 !important;
        }
    }

    button.bg-lime-dark {

        &:hover,
        &:focus {
            background-color: #4a7f20 !important;
        }
    }

    .bg-lime-darker {
        background-color: #315415 !important;
    }

    a.bg-lime-darker {

        &:hover,
        &:focus {
            background-color: #192b0b !important;
        }
    }

    button.bg-lime-darker {

        &:hover,
        &:focus {
            background-color: #192b0b !important;
        }
    }

    .bg-lime-darkest {
        background-color: #192a0b !important;
    }

    a.bg-lime-darkest {

        &:hover,
        &:focus {
            background-color: #010200 !important;
        }
    }

    button.bg-lime-darkest {

        &:hover,
        &:focus {
            background-color: #010200 !important;
        }
    }

    .bg-blue-1 {
        background-color: #0061da;
        color: $white  !important;
    }

    .bg-blue {
        background: $blue;
        color: $white  !important;
    }

    a.bg-blue {

        &:hover,
        &:focus {
            background-color: #4032f1 !important;
        }
    }

    button.bg-blue {

        &:hover,
        &:focus {
            background-color: #4032f1 !important;
        }
    }

    .bg-indigo {
        background: $indigo;
        color: $white  !important;
    }

    a.bg-indigo {

        &:hover,
        &:focus {
            background-color: #3f51c1 !important;
        }
    }

    button.bg-indigo {

        &:hover,
        &:focus {
            background-color: #3f51c1 !important;
        }
    }

    .bg-purple-gradient {
        background: linear-gradient(to bottom right, $purple 0%, #647dee 100%) !important;
        color: $white  !important;
    }

    a.bg-purple-gradient {

        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }

    button.bg-purple-gradient {

        &:hover,
        &:focus {
            background-color: #8c31e4 !important;
        }
    }

    .bg-pink {
        background: $pink  !important;
        color: $white  !important;
    }

    .bg-darkpink {
        background-color: #14a485 !important;
        color: $white  !important;
    }

    a.bg-pink {

        &:hover,
        &:focus {
            background-color: #f33d7a !important;
        }
    }

    button.bg-pink {

        &:hover,
        &:focus {
            background-color: #f33d7a !important;
        }
    }

    .bg-red {
        background: $red;
        color: $white  !important;
    }

    a.bg-red {

        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }

    button.bg-red {

        &:hover,
        &:focus {
            background-color: #a11918 !important;
        }
    }

    .bg-orange {
        background: $orange;
        color: $white  !important;
    }

    a.bg-orange {

        &:hover,
        &:focus {
            background-color: #fc7a12 !important;
        }
    }

    button.bg-orange {

        &:hover,
        &:focus {
            background-color: #fc7a12 !important;
        }
    }

    .bg-yellow-1 {
        background-color: $yellow;
        color: $white  !important;
    }

    .bg-yellow {
        background: $yellow;
        color: $white  !important;
    }

    a.bg-yellow {

        &:hover,
        &:focus {
            background-color: #c29d0b !important;
        }
    }

    button.bg-yellow {

        &:hover,
        &:focus {
            background-color: #c29d0b !important;
        }
    }

    .bg-green-1 {
        background-color: $green;
        color: $white  !important;
    }

    .bg-green {
        background: $green;
        color: $white  !important;
    }

    a.bg-green {

        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }

    button.bg-green {

        &:hover,
        &:focus {
            background-color: #448700 !important;
        }
    }

    .bg-teal {
        background: $teal  !important;
    }

    a.bg-teal {

        &:hover,
        &:focus {
            background-color: #22a193 !important;
        }
    }

    button.bg-teal {

        &:hover,
        &:focus {
            background-color: #22a193 !important;
        }
    }

    .bg-cyan {
        background: $cyan  !important;
        color: $white  !important;
    }

    a.bg-cyan {

        &:hover,
        &:focus {
            background-color: #117a8b !important;
        }
    }

    button.bg-cyan {

        &:hover,
        &:focus {
            background-color: #117a8b !important;
        }
    }

    a.bg-white {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    button.bg-white {

        &:hover,
        &:focus {
            background-color: $transparent-theme  !important;
        }
    }

    .bg-gray {
        background: $gray  !important;
    }

    a.bg-gray {

        &:hover,
        &:focus {
            background-color: #6c757d !important;
        }
    }

    button.bg-gray {

        &:hover,
        &:focus {
            background-color: #6c757d !important;
        }
    }

    .bg-lightpink-red {
        color: #ff7088 !important;
    }

    a.bg-gray-dark {

        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }

    button.bg-gray-dark {

        &:hover,
        &:focus {
            background-color: #1d2124 !important;
        }
    }

    .bg-azure {
        background: $azure;
    }

    a.bg-azure {

        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }

    button.bg-azure {

        &:hover,
        &:focus {
            background-color: #1594ef !important;
        }
    }

    .bg-purple-1 {
        background: $purple;
    }

    .bg-lime {
        background: $lime;
    }

    a.bg-lime {

        &:hover,
        &:focus {
            background-color: #63ad27 !important;
        }
    }

    button.bg-lime {

        &:hover,
        &:focus {
            background-color: #63ad27 !important;
        }
    }

    .bg-square {
        color: $white;
        background: #868e96;
    }

    .bg-primary-light {
        background: $transparent-theme;
    }

    .bg-google-plus {
        background-color: #dd4b39;
    }

    .bg-pinterest {
        background: linear-gradient(to right bottom, #c51629 0%, #bd081c 100%);
    }

    .bg-light-gray {
        background-color: $transparent-theme;
    }

    .bg-progress-white {
        background-color: #eaeceb;
    }

    .bg-dribbble {
        background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
    }

    .bg-google {
        background: linear-gradient(to bottom right, #e64522, #c33219) !important;
        color: $white;
    }

    /*--bg-transparents--*/
    .bg-success-transparent {
        background-color: rgba(0, 230, 130, 0.3) !important;
    }

    .bg-info-transparent {
        background-color: #123d68 !important;
    }

    .bg-warning-transparent {
        background-color: rgba(255, 162, 43, 0.1) !important;
    }

    .bg-danger-transparent {
        background-color: rgba(255, 56, 43, 0.3) !important;
    }

    .bg-pink-transparent {
        background-color: rgba(213, 109, 252, 0.1) !important;
    }

    .bg-purple-transparent {
        background-color: rgba(96, 77, 216, 0.1) !important;
    }

    .bg-dark-transparent {
        background-color: rgba(0, 0, 0, 0.15) !important;
    }

    .bg-white-transparent {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }

    .bg-secondary-transparent {
        background-color: rgba(5, 195, 251, 0.1) !important;
    }

    .shadow-primary {
        box-shadow: 0 7px 30px $primary-03  !important;
    }

    .shadow-secondary {
        box-shadow: 0 7px 30px rgba(130, 207, 242, 0.1) !important;
    }

    .shadow-warning {
        box-shadow: 0 7px 30px rgba(251, 176, 52, 0.1) !important;
    }

    .shadow-info {
        box-shadow: 0 7px 30px rgba(40, 146, 235, 0.5) !important;
    }

    .shadow-success {
        box-shadow: 0 7px 30px rgba(26, 122, 16, 0.1) !important;
    }

    .shadow-danger {
        box-shadow: 0 7px 30px rgba(245, 167, 184, 0.1) !important;
    }

    .google-plus1 {
        background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
    }

    .pinterest1 {
        background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
    }

    .bg-default {
        background: #e9e9f1;
        color: $default-color;
    }

    /*--Box-shadow--*/
    .border {
        border-color: $transparent-border  !important;
        border-color: $transparent-border;
    }

    .border-top {
        border-top-color: $transparent-border  !important;
    }

    .border-end {
        border-right-color: $transparent-border  !important;
    }

    .border-bottom {
        border-bottom-color: $transparent-border  !important;
    }

    .border-start {
        border-left-color: $transparent-border  !important;
    }

    .border-secondary {
        border: 1px solid $secondary  !important;
    }

    .border-success {
        border-color: #1bbfa7 !important;
    }

    .border-info {
        border-color: $azure  !important;
    }

    .border-warning {
        border-color: #ecb403 !important;
    }

    .border-danger {
        border-color: #f82649 !important;
    }

    .border-pink {
        border-color: $pink  !important;
    }

    .border-orange {
        border-color: $orange  !important;
    }

    .border-light {
        border-color: #f8f9fa !important;
    }

    .border-transparent {
        border-color: #656a71 !important;
    }

    .border-dark {
        border-color: $dark  !important;
    }

    .border-white {
        border-color: $white  !important;
    }

    .border-end-1 {
        border-right-color: #d5dce3;
    }

    /*------- Alignments & values-------*/
    .text-white-transparent,
    .text-white-transparent-1 {
        color: $white  !important;
    }

    a.text-primary {

        &:hover,
        &:focus {
            color: $primary-1  !important;
        }
    }

    .text-secondary {
        color: $secondary  !important;
    }

    a.text-secondary {

        &:hover,
        &:focus {
            color: $secondary  !important;
        }
    }

    .text-success {
        color: #1fcfb5 !important;
    }

    a.text-success {

        &:hover,
        &:focus {
            color: #448700 !important;
        }
    }

    .text-info {
        color: #538ed7 !important;
    }

    a.text-info {

        &:hover,
        &:focus {
            color: #1594ef !important;
        }
    }

    .alert-info {
        color: #538ed7 !important;
    }

    .alert-info .alert-link {
        color: #79b6ff;
    }

    .text-warning {
        color: $warning  !important;
    }

    a.text-warning {

        &:hover,
        &:focus {
            color: #c29d0b !important;
        }
    }

    .text-danger {
        color: #fb7d92 !important;
    }

    a.text-danger {

        &:hover,
        &:focus {
            color: #d22827 !important;
        }
    }

    .text-light {
        color: #ebedef !important;
    }

    a.text-light {

        &:hover,
        &:focus {
            color: #dae0e5 !important;
        }
    }

    .text-dark {
        color: $white  !important;
    }

    a.text-dark {

        &:hover,
        &:focus {
            color: $primary-1  !important;
        }
    }

    .text-body {
        color: #495057 !important;
    }

    .text-muted {
        color: $white-7  !important;
    }

    .text-black-50 {
        color: rgba(0, 0, 0, 0.5) !important;
    }

    .text-white-50 {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .text-hide {
        color: transparent;
        background-color: transparent;
    }

    /*----- Typography ------*/
    .heading-inverse {
        background-color: #333;
        color: $white;
    }

    .heading-success {
        background-color: #1643a3;
        color: $white;
    }

    .heading-info {
        background-color: $azure;
        color: $white;
    }

    .heading-warning {
        background-color: #ecb403;
        color: $white;
    }

    .heading-danger {
        background-color: #fb7d92;
        color: $white;
    }

    .text-inherit {
        color: inherit !important;
    }

    .text-default {
        color: $white  !important;
    }

    .text-muted-dark {
        color: #1c232f !important;
    }

    .text-fb-blue {
        color: #234684 !important;
    }

    .text-blue {
        color: #467fcf !important;
    }

    .text-indigo {
        color: $indigo  !important;
    }

    .text-purple {
        color: #867efc !important;
    }

    .text-lightpink-red {
        color: #ff7088 !important;
    }

    .text-lightgreen {
        color: #26eda2 !important;
    }

    .text-pink {
        color: #ec82ef !important;
    }

    .text-red {
        color: #fb7d92 !important;
    }

    .text-orange {
        color: $orange  !important;
    }

    .text-yellow {
        color: #ecb403 !important;
    }

    .text-green {
        color: $green  !important;
    }

    .text-green-1 {
        color: #0dff01 !important;
    }

    .text-teal {
        color: #2bcbba !important;
    }

    .text-cyan {
        color: #17a2b8 !important;
    }

    .text-white {
        color: $white  !important;
    }

    .text-gray {
        color: #969696 !important;
    }

    .text-gray-dark {
        color: $dark  !important;
    }

    .text-azure {
        color: $azure  !important;
    }

    .text-lime {
        color: $lime  !important;
    }

    .text-transparent {
        color: #332525;
    }

    .text-facebook {
        color: #3b5998;
    }

    .text-google-plus {
        color: #dd4b39;
    }

    .text-twitter {
        color: #1da1f2;
    }

    .text-pinterest {
        color: #bd081c;
    }

    .text-secondary-gradient {
        background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%);
        -webkit-text-fill-color: transparent;
        background: linear-gradient(to right, #cd489c 0%, #ce4ba4 100%);
        -webkit-text-fill-color: transparent;
    }

    .text-info-gradient {
        background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
        -webkit-text-fill-color: transparent;
    }

    .text-success-gradient {
        background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%);
        -webkit-text-fill-color: transparent;
    }

    .text-facebook-gradient {
        background: linear-gradient(to right bottom, #1e3c72 0%, #3d6cbf 100%);
        -webkit-text-fill-color: transparent;
    }

    .text-twitter-gradient {
        background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
        -webkit-text-fill-color: transparent;
    }

    .text-google-plus-gradient {
        background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
        -webkit-text-fill-color: transparent;
    }

    /*--text-shadow--*/
    .text-success-shadow {
        text-shadow: 0 5px 10px rgba(19, 191, 166, 0.3) !important;
    }

    .text-info-shadow {
        text-shadow: 0 5px 10px rgba(7, 116, 248, 0.3) !important;
    }

    .text-warning-shadow {
        text-shadow: 0 5px 10px rgba(255, 162, 43, 0.3) !important;
    }

    .text-danger-shadow {
        text-shadow: 0 5px 10px rgba(255, 56, 43, 0.3) !important;
    }

    .text-pink-shadow {
        text-shadow: 0 5px 10px rgba(213, 109, 252, 0.3) !important;
    }

    .text-purple-shadow {
        text-shadow: 0 5px 10px rgba(96, 77, 216, 0.3) !important;
    }

    .text-dark-shadow {
        text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    }

    .text-white-shadow {
        text-shadow: 0 5px 10px rgba(255, 255, 255, 0.3) !important;
    }

    .text-secondary-shadow {
        text-shadow: 0 5px 10px rgba(130, 207, 242, 0.3);
    }

    .alert-success {
        hr {
            border-top-color: $success;
        }
    }

    .alert-info {
        hr {
            border-top-color: $info;
        }
    }

    .alert-warning {
        hr {
            border-top-color: $warning;
        }
    }

    .alert-danger {
        hr {
            border-top-color: $danger;
        }
    }
}

.transparent-mode .sidebar {
    background: $primary-1;
    border-color: $transparent-border;
    box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
}

@supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
    .transparent-mode .sidebar {
        background: transparent !important;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border-color: $transparent-border;
        box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
    }
}

.transparent-mode {
    .light-layout {
        display: none;
    }

    .dark-layout {
        display: block;
    }
}

.transparent-mode .slide.is-expanded a {
    color: $white-8;
}

.transparent-mode .select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #30304d;
}

.transparent-mode .select2-container--default .select2-results>.select2-results__options {
    box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
}

.transparent-mode .select2-dropdown {
    background-color: $transparent-body;
    border-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .side-header .header-brand-img.desktop-logo {
    display: block !important;
}

.transparent-mode .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.transparent-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.transparent-mode .header-brand .header-brand-img.logo-3 {
    display: none;
}

.transparent-mode .header-brand .header-brand-img.logo {
    display: block;
}

.transparent-mode.sidebar-mini .app-header .light-logo1 {
    display: none !important;
}

.transparent-mode.sidenav-toggled .header-brand-img.light-logo {
    display: none !important;
}

.transparent-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
}

.transparent-mode.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.transparent-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block !important;
}

.transparent-mode.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.transparent-mode.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.transparent-mode.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block;
}

.transparent-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
}

.transparent-mode.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block;
}

.transparent-mode.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .header-brand-img.light-logo {
    display: none !important;
}

.transparent-mode.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block;
}

.transparent-mode.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-header .header-brand-img.light-logo {
    display: none !important;
}

@media (max-width: 991px) {
    .transparent-mode {
        .app-header.header .header-brand-img.desktop-logo {
            display: block;
        }

        &.app .app-sidebar {
            top: 72px;
        }
    }
}

//FULL CALENDAR
.transparent-mode {
    #external-events {
        border-color: $transparent-border;
    }

    .fc-theme-standard td,
    .fc-theme-standard th {
        border-color: $transparent-border;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: 0px solid $transparent-border;
        border-top: 1px solid $transparent-border;
        border-left: 1px solid $transparent-border;
    }

    .fc .fc-daygrid-day-number {
        color: $white;
    }

    .fc .fc-daygrid-day.fc-day-today {
        background-color: $transparent-theme;
    }

    .fc-theme-standard {
        .fc-list {
            border-color: $transparent-border;
        }

        .fc-list-day-cushion {
            background: $transparent-body;
        }
    }

    .fc .fc-list-event:hover td {
        background: $transparent-body;
    }

    .fc-list-event.fc-event {
        color: $white  !important;
    }

    .fc-direction-ltr .fc-list-day-text,
    .fc-direction-rtl .fc-list-day-side-text {
        color: $white;
    }

    .fc-direction-ltr .fc-list-day-side-text,
    .fc-direction-rtl .fc-list-day-text {
        color: $white;
    }
}

//SWEET ALERT
.transparent-mode {
    .sweet-alert {
        background: $transparent-body;
    }

    .sweet-alert {
        h2 {
            color: $white;
        }

        P {
            color: $white-7;
        }
    }

    .alert-default {
        background-color: $transparent-theme;
    }
}

//RANGE SLIDER
.transparent-mode {

    .irs-line-mid,
    .irs-line-right,
    .irs-line-left {
        background-color: $transparent-body;
    }

    .irs-from,
    .irs-to,
    .irs-single {
        background: $transparent-body;
    }

    .irs-from,
    .irs-to,
    .irs-single {
        color: $white;
    }

    .irs-min,
    .irs-max {
        color: $white;
        background: $transparent-body;
    }

    .irs-grid-text {
        color: $white-7;
    }

    .irs-modern .irs-slider,
    .irs-outline .irs-slider {
        background-color: $transparent-body;
    }

    .irs-bar {
        background: $primary-1;
    }

    .irs-slider:before {
        background-color: $primary-1;
    }

    .irs-outline .irs-line {
        border-color: $primary-1;
    }
}

.transparent-mode {
    .tree {
        li {
            color: $white;

            a {
                color: $white;
            }

            &.branch li {
                background: transparent;
            }
        }

        ul {
            &:before {
                border-left-color: $white-4;
            }

            li:before {
                border-top-color: $white-4;
            }
        }
    }

    .dTree a {
        color: $white;
    }
}

//TABS
.transparent-mode {
    .tab_wrapper {
        .content_wrapper {
            border-color: $transparent-border;

            .accordian_header {
                border-bottom-color: $transparent-border;
                border-top-color: $transparent-border;
            }

            .accordian_header .arrow {
                background: transparent;
                border-top-color: $white-3;
                border-left-color: $white-3;
            }

            .accordian_header.active {
                border-color: $transparent-border;
            }
        }

        &.right_side {
            .content_wrapper {
                border-color: $transparent-border;
            }

            >ul li {
                &.active {
                    border-color: $transparent-border;
                }

                &.active::before {
                    background: $transparent-border;
                }

                border-left-color: $transparent-border;

                &:after {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
        }

        >ul {
            border-bottom-color: $transparent-border  !important;
        }

        >ul li {
            border-color: $transparent-border;

            &.active:after {
                background: transparent;
            }
        }
    }
}

//FILE UPLOAD
.transparent-mode {
    .dropify-wrapper {
        color: $white;
        background-color: $transparent-theme;
        border-color: $transparent-border;

        .dropify-preview {
            background-color: $transparent-theme;
        }
    }

    .dropify-wrapper .dropify-message span.file-icon {
        color: $white-7;
    }

    .dropify-wrapper:hover {
        background-image: linear-gradient(-45deg,
                $transparent-body 25%,
                transparent 25%,
                transparent 50%,
                $transparent-body 50%,
                $transparent-body 75%,
                transparent 75%,
                transparent);
    }

    .ff_fileupload_wrap .ff_fileupload_dropzone {

        &:focus,
        &:active {
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }
    }

    .ff_fileupload_wrap .ff_fileupload_dropzone {
        border-color: $transparent-border;
        background-color: $transparent-theme;

        &:hover {
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }
    }

    .sp-replacer {
        border-color: $transparent-border;
        background: $transparent-body;
        color: $white;

        &:hover,
        &.sp-active {
            border-color: $transparent-border;
            color: $white;
        }
    }

    .sp-container {
        background: $transparent-body;
        box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
        border-color: $transparent-border;
    }

    .sp-picker-container {
        border-left: 0;
    }

    .ui-timepicker-wrapper {
        background: $transparent-body;
        border-color: rgba(255, 255, 255, 0.07);
        box-shadow: 0 16px 18px 0 $transparent-theme;
    }

    .ui-timepicker-list li {
        color: rgba(255, 255, 255, 0.8);
    }

    .datepicker {

        .datepicker-switch,
        td,
        th {
            color: $white  !important;
        }
    }

    .datepicker .datepicker-switch:hover,
    .datepicker .prev:hover,
    .datepicker .next:hover,
    .datepicker tfoot tr th:hover {
        color: $white-7  !important;
    }

    .datepicker-dropdown.datepicker-orient-top {

        &:after,
        &:before {
            border-top-color: $transparent-body;
        }
    }

    .datepicker table tr td span.old,
    .datepicker table tr td span.new {
        color: $white-7;
    }

    .SumoSelect {
        >.CaptionCont {
            border-color: $transparent-border;
            background-color: transparent;
            color: $white-7;
        }

        &.disabled>.CaptionCont {
            border-color: $transparent-border;
            background: $transparent-theme;
            border-radius: 0;
        }
    }

    .SumoSelect {
        &.open>.optWrapper {
            background: $transparent-body;
        }

        >.optWrapper {
            border-color: $transparent-border;

            >.options li.opt {
                border-bottom-color: $transparent-border;

                &:hover {
                    background-color: #262641;
                }
            }
        }
    }

    .SumoSelect {
        &.open .search-txt {
            background: $transparent-theme;
            color: $white-7;
        }

        .select-all {
            background-color: $transparent-theme;
            border-bottom-color: $transparent-border;
        }

        >.optWrapper {
            >.MultiControls {
                border-top: 1px solid rgba(255, 255, 255, 0.12);
                background-color: $transparent-theme;
            }

            &.multiple>.MultiControls>p:hover {
                background-color: #393958;
            }
        }
    }

    datepicker-dropdown {
        &.datepicker-orient-top {

            &:after,
            &:before {
                border-top: 7px solid #3e3e50;
            }
        }

        &:after,
        &:before {
            border-bottom-color: #2e2e4a;
        }
    }

    .datepicker table tr td span {

        &:hover,
        &.focused {
            background: $transparent-theme  !important;
            color: #dedefd !important;
        }
    }

    .datepicker .prev,
    .datepicker .next {
        background: $transparent-theme;
    }

    .datepicker .prev:hover,
    .datepicker .next:hover {
        background: $transparent-theme  !important;
        color: $white-7  !important;
    }

    .datepicker-dropdown:after {
        border-bottom-color: $transparent-border;
    }

    .datepicker-dropdown::before {
        border-bottom-color: $transparent-border;
    }

    .ms-choice {
        color: $white-7;
        background-color: transparent;
        border-color: $transparent-border;

        &.disabled {
            background-color: $transparent-theme;
            border-color: $transparent-border;
        }
    }

    .ms-drop {
        &.bottom {
            box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
        }

        color: rgba(255, 255, 255, 0.7);
        background-color: $transparent-body;
        border-color: $transparent-border;
    }

    .select2-dropdown {
        background-color: $transparent-body;
        border-color: $transparent-border;
    }

    .select2-container--default {
        .select2-results__option[aria-selected="true"] {
            background-color: $transparent-theme;
        }

        &.select2-container--disabled .select2-selection--single {
            background-color: $transparent-theme;
        }
    }

    .ms-search input {
        background: $transparent-theme;
        border-color: $transparent-border;
    }

    .transfer-double {
        background-color: transparent;
    }

    .transfer-double-content-tabs {
        .tab-item-name.tab-active {
            background: $transparent-theme;
        }

        border-bottom-color: $transparent-border;
    }

    .transfer-double-content-left,
    .transfer-double-content-right {
        border-color: $transparent-border;
    }

    .transfer-double-list-footer {
        border-top-color: $transparent-border;
    }

    .transfer-double-list-search-input {
        border-color: $transparent-border;
        background-color: $transparent-theme;

        &::placeholder {
            color: $white-5;
        }
    }

    .transfer-double-list-main::-webkit-scrollbar-track {
        background-color: $transparent-theme;
    }

    .checkbox-group label:before {
        background-color: $transparent-theme;
        border-color: $transparent-border;
    }

    .transfer-double-content-param {
        border-bottom-color: $transparent-border;
    }

    .transfer-double-selected-list-search-input {
        border-color: $transparent-border;
        background-color: $transparent-theme;

        &::placeholder {
            color: $white-5;
        }
    }

    .btn-select-arrow {
        color: $white;
        background: $transparent-theme;
        border-color: $transparent-border;
    }

    .multi-wrapper {
        .search-input {
            border-bottom-color: $transparent-border;
            background: transparent;
        }

        border-color: $transparent-border;

        .item-1 {
            color: $white;
            background: transparent;
        }

        .selected-wrapper,
        .item-group-1 .group-label {
            background: $transparent-theme;
        }
    }

    .iti__dial-code {
        color: $white-8;
    }

    .multi-wrapper .non-selected-wrapper {
        background: $transparent-theme;
        border-right-color: $transparent-border;
    }

    .iti input {
        background-color: $transparent-theme;
        border-color: $transparent-border;
        color: $white;

        &[type="tel"],
        &[type="text"] {
            border-color: $transparent-border;
            background-color: $transparent-theme;
            border-right-color: $transparent-border;
            color: $white;
        }
    }

    .iti__selected-flag {
        border-right-color: $transparent-border;
    }

    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
        background-color: $transparent-theme;
        border-top-color: $transparent-border;
    }

    .iti__country-list {
        border-color: $transparent-border;
        background-color: $transparent-body;
        border-right-color: $transparent-border;
    }

    .iti__country.iti__highlight {
        background-color: $transparent-theme;
    }

    .iti__divider {
        border-bottom-color: $transparent-border;
    }
}

//SUMMERNOTE
.transparent-mode {
    .note-editor.note-frame.panel.panel-default .panel-heading {
        background-color: transparent;
        border-bottom-color: $transparent-border;
    }

    .note-editor.note-frame.panel.panel-default.fullscreen .panel-heading.note-toolbar {
        background-color: $transparent-body;
    }

    .note-btn.btn-default {
        background-color: transparent;
        border-color: $transparent-border;
        color: $white;
    }

    .panel-default.fullscreen .note-editable {
        background-color: $transparent-body;
    }

    .note-editor.note-airframe,
    .note-editor.note-frame {
        border-color: $transparent-border;
    }

    .note-editor .btn-default:not(:disabled):not(.disabled):active {
        background-color: $transparent-theme;
        border-color: $transparent-border;
        color: $white;
    }

    .form-control-file::-webkit-file-upload-button {
        background-color: $transparent-body;
        color: $white;
        border-color: $transparent-border;
    }

    .note-editor.note-airframe .note-editing-area .note-codable,
    .note-editor.note-frame .note-editing-area .note-codable {
        background-color: $transparent-body;
        color: $white-7;
    }
}

//RICHTEXT
.transparent-mode {
    .richText {
        .richText-editor {
            background-color: transparent;
            border-left-color: rgba(255, 255, 255, 0);
        }

        .richText-toolbar ul {
            border-bottom-color: $transparent-border;
        }

        .richText-toolbar ul li a {
            color: $white;

            &:hover {
                background-color: $transparent-theme;
            }

            .richText-dropdown-outer .richText-dropdown {
                background-color: $transparent-body;
                border-color: $transparent-border;

                .richText-dropdown-close {
                    background: $transparent-body;
                    color: $white-7;
                }
            }
        }

        .richText-form {
            input {

                &[type="text"],
                &[type="file"],
                &[type="number"] {
                    border-color: $transparent-border;
                    background: $transparent-theme;
                    color: $white;
                }
            }

            select {
                border-color: $transparent-border;
                background: $transparent-theme;
                color: $white;
            }
        }

        .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
            &:hover {
                background: $transparent-theme;
            }

            border-bottom-color: $transparent-border;
        }

        .richText-undo,
        .richText-redo {
            border-right-color: $transparent-border;
        }
    }
}

//QUILL EDITOR
.transparent-mode {
    .ql-toolbar.ql-snow {
        .ql-picker-label {
            border-color: $transparent-border;
            background: $transparent-body;
            color: $white;
        }

        border-color: $transparent-border;
    }

    .ql-container.ql-snow {
        border-color: $transparent-border;
    }

    .ql-snow {

        &.ql-toolbar button,
        .ql-toolbar button {
            border-color: $transparent-border;
            background: $transparent-body;
            color: $white;
        }

        &.ql-toolbar button:last-child,
        .ql-toolbar button:last-child {
            border-right-color: $transparent-border;
        }
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        background-color: $transparent-body;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        border-color: $transparent-border;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
        border-color: $transparent-border;
    }

    .ql-snow .ql-formats {
        color: $white;
    }

    .ql-snow .ql-tooltip {
        background-color: $transparent-body;
        border-color: $transparent-border;
        box-shadow: 0px 0px 5px $transparent-theme;
        color: $white;
        margin-left: 100px;
    }

    .ql-snow .ql-stroke {
        stroke: $white-5;
    }

    .ql-snow.ql-toolbar button:hover,
    .ql-snow .ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus,
    .ql-snow .ql-toolbar button:focus,
    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow .ql-toolbar button.ql-active,
    .ql-snow.ql-toolbar .ql-picker-label:hover,
    .ql-snow .ql-toolbar .ql-picker-label:hover,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active,
    .ql-snow.ql-toolbar .ql-picker-item:hover,
    .ql-snow .ql-toolbar .ql-picker-item:hover,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
        color: $white-7;
    }

    .ql-tooltip.ql-editing {
        input {

            &[type="text"],
            &[type="file"],
            &[type="number"] {
                border-color: $transparent-border;
                background: $transparent-theme;
                color: $white;
            }
        }
    }

    .ql-bubble .ql-tooltip {
        background-color: $transparent-theme;
    }

    .ql-snow a {
        color: $white-7;
    }
}

//FORM WIZARD
.transparent-mode {
    .sw-theme-dots {
        >ul.step-anchor {
            background: transparent;
            border-color: $transparent-border;

            &:before {
                background-color: $transparent-body;
            }

            >li>a:before {
                background: $transparent-body;
            }

            >li.active>a::after {
                background: $white-7;
            }

            >li.done>a {
                color: $white;
            }
        }

        .step-content {
            background-color: transparent;
        }

        .sw-toolbar {
            background: transparent;
        }
    }

    .wizard {
        border-color: $transparent-border;
        background-color: transparent;
        border-radius: 3px;

        > {
            .content {
                border-top-color: $transparent-border;
                border-bottom-color: $transparent-border;

                >.title {
                    color: #dedefd;
                }
            }

            .actions .disabled a {
                background-color: $white-1;
                color: rgba(255, 255, 255, 0.62);
            }

            .steps {
                .current a {

                    .number,
                    &:active .number,
                    &:hover .number {
                        background-color: $primary-1;
                    }
                }

                a {

                    .number,
                    &:active .number,
                    &:hover .number {
                        background-color: $white-1;
                    }
                }
            }
        }

        &.vertical> {

            .content,
            .actions {
                border-left-color: $transparent-border;
            }
        }

        >.steps {
            .done a {

                .number,
                &:active .number,
                &:hover .number {
                    background-color: #0dcd94;
                }
            }

            a {

                .number,
                &:active .number,
                &:hover .number {
                    background-color: $white-1;
                }
            }
        }
    }
}

//OWL CAROUSEL
.transparent-mode {
    .owl-nav button {
        background: $transparent-body  !important;
        border-color: $transparent-border  !important;
    }
}

//CHARTS
.transparent-mode {
    .flot-text {
        color: $white-7  !important;
    }

    tspan {
        fill: $white-7  !important;
    }

    .nvd3 {
        text {
            fill: $white-7;
        }

        .nv-axis line {
            stroke: rgba(119, 119, 142, 0.2);
        }

        .nv-discretebar .nv-groups text,
        .nv-multibarHorizontal .nv-groups text {
            fill: $white;
        }
    }

    .countdown li {
        background: $transparent-body;
        border: 5px solid $transparent-border;
        color: $white;
    }
}

.transparent-mode .app-sidebar.sidemenu-scroll .side-header {
    background: $primary-1;
}

@supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .transparent-mode .app-sidebar.sidemenu-scroll .side-header {
        background: transparent !important;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }
}

.transparent-mode .bg-recentorder {
    background: linear-gradient(to right, var(--transparent-body) 0%, tranparent 100%), url("../images/media/bg2.jpg");
}

.transparent-mode .bg-recentorder:before {
    background: none;
}

.rtl.transparent-mode {
    .app-sidebar {
        border-left-color: rgba(255, 255, 255, 0.1);
        border-right: inherit;
    }

    .side-header {
        border-left-color: rgba(255, 255, 255, 0.05);
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 10px solid rgba(255, 255, 255, 0.1);
        border-left: 0 solid rgba(255, 255, 255, 0.1);
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
        border-right: 0 !important;
        border-left: 10px solid rgba(255, 255, 255, 0.1) !important;
        right: -10px !important;
        left: auto;
    }

    .notification .notification-body:before {
        border-left-color: rgba(0, 0, 0, 0.2) !important;
        border-right-color: transparent !important;
    }

    .border-end {
        border-left-color: rgba(255, 255, 255, 0.1) !important;
        border-right: transparent !important;
    }

    .iti__selected-flag {
        border-left-color: rgba(255, 255, 255, 0.2);
        border-right-color: inherit;
    }

    .card-group {
        .card.border-end {
            border-left: 1px solid $transparent-border  !important;
            border-right: inherit !important;
        }
    }

    .dtr-bs-modal .dtr-details tr td:first-child {
        border-left: 1px solid $transparent-border;
    }

    .tab_wrapper.right_side .content_wrapper {
        border-right-color: $border-dark  !important;
    }

    .tab_wrapper.right_side>ul li {
        border-right-color: $border-dark  !important;
    }

    .richText .richText-toolbar ul li a {
        border-left: rgba(156, 162, 161, 0.2) solid 1px;
        border-right: inherit;
    }
}

.rtl.transparent-mode .main-content-body-chat {
    .border-start-0 {
        border-left-color: $transparent-border  !important;
    }
}

.transparent-mode .buttons-columnVisibility.dropdown-item:hover,
.transparent-mode .buttons-columnVisibility.dropdown-item:focus,
.transparent-mode .buttons-columnVisibility.dropdown-item.active,
.transparent-mode .buttons-columnVisibility.dropdown-item:active {
    color: $white;
}

.transparent-mode .dataTables_wrapper .selected {
    background: $transparent-body;
}

.transparent-mode .dropdown-item:hover,
.transparent-mode .dropdown-item:focus,
.transparent-mode .dropdown-item.active,
.transparent-mode .dropdown-item:active {
    color: $white;
}

.transparent-mode .list-group-item a {
    color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .container-login100 {
    .text-primary {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .tab-content i,
    .tabs-menu2 ul li .active {
        color: $white;
    }

    .social-login i {
        color: $white;
    }

    .validate-form.login100-form {
        a {
            color: rgb(255, 255, 255) !important;
        }
    }
}

@media (max-width: 991px) {
    .transparent-mode.sidebar-gone.sidenav-toggled.sidebar-mini .side-menu {
        margin-top: 0px !important;
    }

    .transparent-mode .navresponsive-toggler span {
        color: $white  !important;
    }
}

.transparent-mode.light-menu .hor-header.header {
    border-bottom-color: rgba(233, 237, 244, 0.2);
}

@media (max-width: 991px) {
    .transparent-mode.header-light .navresponsive-toggler span {
        color: $primary-1  !important;
    }
}

.transparent-mode .onoffswitch2-label {
    background-color: $transparent-theme;
}

.transparent-mode .onoffswitch2-label:before {
    background-color: #d3d3d3;
}

.transparent-mode .theme-layout.nav-link-bg.layout-setting {
    display: none;
}

@media (max-width: 991px) {
    .app.sidebar-mini.transparent-mode.header-light {
        .side-header .header-brand-img.light-logo1 {
            display: none !important;
        }
    }

    .app.sidebar-mini.transparent-mode.header-light {
        .side-header .header-brand-img.desktop-logo {
            display: block !important;
        }
    }

    .app.sidebar-mini.transparent-mode.light-menu.header-light {
        .side-header .header-brand-img.light-logo1 {
            display: block !important;
        }
    }

    .app.sidebar-mini.transparent-mode.light-menu.header-light {
        .side-header .header-brand-img.desktop-logo {
            display: none !important;
        }
    }

    .app.sidebar-mini.sidebar-gone.transparent-mode.light-menu.header-light.sidenav-toggled {
        .side-header .header-brand-img.light-logo1 {
            display: none !important;
        }
    }
}

.transparent-mode {
    .fc-theme-standard .fc-popover {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: $transparent-body;
    }

    .fc-theme-standard .fc-popover-header {
        background: $transparent-theme;
    }
}

.transparent-mode .card-aside.color-card-radius {
    .card-header {
        border-bottom-color: transparent;
    }
}

.transparent-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    background: transparent;
    color: $white;
}

.transparent-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image {
    background-color: $transparent-theme;
}

.transparent-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    border-color: $transparent-border;
    background-color: $transparent-theme;
}

.transparent-mode {
    .dropify-wrapper .dropify-preview .dropify-render img {
        background-color: transparent;
    }

    &.rtl .table.border-dashed thead th:last-child {
        border-left-color: transparent;
    }

    &.rtl .table thead th:last-child {
        border-left-color: $transparent-border;
    }

    .fc .fc-list-event:hover td {
        color: $white;
    }

    #navbar-example2 {
        .nav-link {
            color: $white;
        }
    }

    &.icontext-menu .side-menu__item:hover .side-menu__icon,
    &.icontext-menu .side-menu__item:hover .side-menu__label,
    &.icontext-menu .side-menu__item:focus .side-menu__icon,
    &.icontext-menu .side-menu__item:focus .side-menu__label {
        color: $white  !important;
    }

    &.hover-submenu .slide-menu a.active {
        color: $white;

        .sub-side-menu__label {
            color: $white;
        }
    }

    &.hover-submenu1 .slide-menu a.active {
        color: $white;

        .sub-side-menu__label {
            color: $white;
        }
    }

    &.horizontal .slide-menu a.active {
        color: $white;

        .sub-side-menu__label {
            color: $white;
        }
    }
}

.transparent-mode {
    .qty {
        &:focus {
            background: transparent;
        }
    }
}

.transparent-mode .card-transparent {

    &.bg-primary-transparent,
    &.bg-secondary-transparent,
    &.bg-info-transparent,
    &.bg-success-transparent,
    &.bg-warning-transparent,
    &.bg-danger-transparent {
        background-color: $black-2  !important;
    }
}

.transparent-mode {
    .btn-primary-light {
        color: $white;
        background: $primary-06  !important;
        border-color: $primary-06  !important;
    }

    .multi-wrapper .search-input {
        &::placeholder {
            color: $white-8;
        }
    }

    .handle-counter {
        input {
            background-color: transparent;
            border: 1px solid $transparent-border;
            color: $text-color;
        }
    }

    .card-group {
        .card.border-end {
            border-right: 1px solid $transparent-border  !important;
        }
    }

    .datetimetable input {
        border-color: $transparent-border;
        background-color: transparent;
        color: $text-color;

        &::placeholder {
            color: $text-color;
        }
    }

    div.dt-datetime {
        background-color: $transparent-body;
        border-color: $transparent-border;
        box-shadow: 0px 16px 18px rgb(0 0 0 / 20%);
    }

    div.dt-datetime table td {
        color: $white-8;
    }

    div.dt-datetime table th {
        color: $text-color;
    }

    div.dt-datetime div.dt-datetime-iconLeft button,
    div.dt-datetime div.dt-datetime-iconRight button,
    div.dt-datetime div.dt-datetime-iconUp button,
    div.dt-datetime div.dt-datetime-iconDown button {
        background: rgb(255 255 255 / 10%);
    }

    div.dt-datetime div.dt-datetime-label:hover {
        border-color: $transparent-border;
        background-color: $white-1;
    }

    .btn-group.file-attach .btn-close.btn-outline-primary {
        border-color: $white-4;
        color: $white-5;
    }

    .pricing-tabs ul.nav-price {
        border: 1px solid $transparent-border;
        background: $transparent-theme;
    }

    .pricing-tabs ul.nav-price li a {
        background: transparent;
        color: $white-8;
    }

    .pricing-tabs ul.nav-price li a.active {
        background: var(--primary-bg-color);
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: $white-6 transparent transparent transparent;
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: transparent transparent $white-6 transparent;
    }

    .fc .fc-list-empty {
        background-color: transparent;
    }

    .c3 path,
    .c3 line {
        stroke: $transparent-border;
    }

    .fc-timegrid-event-harness-inset .fc-timegrid-event {
        box-shadow: 0px 0px 0px 1px $white-2;
    }

    .form-floating>.form-control::placeholder {
        color: transparent !important;
    }

    div.dt-autofill-list {
        background-color: $transparent-body;

        div.dt-autofill-question,
        div.dt-autofill-button {
            border-bottom: 1px dotted $transparent-border
        }

        ul li:hover {
            background-color: $black-1;
        }
    }

    div.dt-button-info {
        background-color: $transparent-body;
        border: 2px solid $transparent-border
    }

    div.dt-button-info h2 {
        border-bottom: 1px solid $transparent-border;
        background-color: $transparent-body;
    }

    .note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-palette-title,
    .note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-palette-title {
        border-bottom: 1px solid $transparent-border;
    }

    .note-children-container {
        a {
            color: $white  !important;
        }
    }

    .is-loading {
        .form-control-plaintext::placeholder {
            color: $white-7;
        }

        .form-control-plaintext:focus-visible {
            border: 1px solid $transparent-border;
        }

        select option:checked {
            background-color: $white-1;
            color: $text-color;
        }

        select[disabled]>option {
            color: $white-4;
        }
    }
}

@media screen and (max-width:991px) {

    .transparent-mode.horizontal .horizontal-main .slide .slide-menu,
    .transparent-mode.horizontal .horizontal-main .slide .sub-slide-menu,
    .transparent-mode.horizontal .horizontal-main .slide .sub-slide-menu2 {
        box-shadow: none !important;
    }
}

.transparent-mode .demo_changer .form_holder {
    background-color: $primary-1;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}


@media (max-width: 767px) {
    .rtl.transparent-mode .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
        border-left: 0 !important;
        border-right: 14px solid rgba(255, 255, 255, 0.2) !important;
    }
}

.transparent-mode .nvd3 .nv-axis path {
    stroke: $transparent-border  !important;
}

.transparent-mode {
    .grid-margin {
        .product-sale {
            .active {
                background-color: transparent !important;
            }

            .text-dark {
                opacity: 0.7;
            }
        }
    }

    .border-primary {
        border-color: $primary-1  !important;
    }

    .footer .btn-link {
        color: $white;
    }

    .main-footer {
        color: $white-8;

        a {
            color: $white;
        }
    }

    .panel-body.tabs-menu-body {
        .tab-content {
            .tab-pane#tab5 {
                .card-body {
                    [target="_blank"] {
                        color: $white  !important;
                    }

                    a {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .btn-outline-danger {
        color: #fb7d92;
        border-color: #fb7d92;
    }

    .nav-link.disabled {
        color: $white-7;
    }

    .iti input {
        &::placeholder {
            color: $white  !important;
            opacity: 0.8;
        }
    }

    .invalid-feedback {
        color: #fb7d92;
    }

    .mapael {
        tspan {
            fill: $black  !important;
        }
    }

    .card-fullscreen {
        background-color: $primary-1 !important;
    }
}